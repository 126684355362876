import React, { useContext, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import CodeMirror from '@uiw/react-codemirror'
import { langs } from '@uiw/codemirror-extensions-langs'
import { xcodeLight } from '@uiw/codemirror-theme-xcode'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { BindingChannels } from 'widgets/binding-channels'
import type { JSONHandler, JSONSettings } from 'widgets/plugins/json-parser'
import type { ChannelsTableData } from 'entities/channel'
import keyToId from 'shared/lib/key-to-id'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import omitObject from 'shared/lib/omit-object'
import AddModal from 'shared/ui/modals/add-modal'
import { FormChannelBinding } from 'shared/ui/form/form-channel-binding'
import formValidate from 'shared/lib/form-validate'

type FieldsType = JSONHandler & {
  'channel-json_name': string
}

export const AddJSONHandler: FC = () => {
  const { state: { settings } } = useContext<ModuleContextType<JSONSettings>>(ModuleContext)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isBindingOpen, setIsBindingOpen] = useState<boolean>(false)
  const [isBindingActive, setIsBindingActive] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  const onAdd = (values: JSONHandler) => {
    if (settings) {
      const set = omitObject(settings, ['uid'])

      setLoading(true)

      set.handlers.push({
        id: 0,
        template: encodeURIComponent(values.template),
        'channel-json': values['channel-json'] || '',
        aliases: []
      })

      set.handlers = set.handlers.map((r: JSONHandler, i: number) => ({ ...r, id: i + 1 }))

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  const onSaveBindingHandler = (data: Partial<ChannelsTableData>[]) => {
    if (data.length) {
      form.setFieldValue('channel-json', keyToId(String(data[0].key)))
      form.setFieldValue('channel-json_name', data[0].name)
    }
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        onClick={() => setIsModalOpen(true)}
      />

      <AddModal
        title="обработчика"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={() => {
          setIsModalOpen(false)
          setTimeout(() => form.resetFields(), 300)
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
        >
          <FormChannelBinding
            label={'Канал'}
            name={'channel-json'}
            onClick={() => {
              setIsBindingOpen(true)
              setIsBindingActive(true)
            }}
            onClear={() => {
              form.setFieldValue('channel-json', '')
              form.setFieldValue('channel-json_name', '')
            }}
          />

          <Form.Item<FieldsType>
            label="Шаблон"
            name="template"
            rules={[{ required: true, message: 'Введите шаблон структуры JSON' }]}
          >
            <CodeMirror
              extensions={[langs.json()]}
              theme={xcodeLight}
              height={'300px'}
            />
          </Form.Item>
        </Form>

        {isBindingActive && (
          <BindingChannels
            boundChannels={[]}
            isOpen={isBindingOpen}
            isMultiple={false}
            onSave={onSaveBindingHandler}
            onClose={() => {
              setIsBindingOpen(false)
              setIsBindingActive(false)
            }}
          />
        )}
      </AddModal>
    </>
  )
}
