/**
 * Возвращает объект, исключая указанные свойства.
 *
 * @param {any} obj - Исходный объект.
 * @param {string[]} keys - Ключи свойств, которые необходимо убрать.
 * */
const omitObject = <T extends Record<string, any>>(obj: T, keys: string[]) => (
  Object.keys(obj).reduce<T>((acc, curr) => {
    if (!keys.includes(curr))
      acc[curr as keyof typeof acc] = obj[curr]

    return acc
  }, {} as T)
)

export default omitObject
