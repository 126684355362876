import React, { FC, ReactNode, useEffect, useReducer, useState } from 'react'
import useSWR from 'swr'
import cn from 'classnames'
import { Spin } from 'antd'
import { useAppSelector } from 'app/store'
import { ModuleContext } from 'layouts/module-layout'
import { ControlModule } from 'features/module/control-module'
import { ResetModule } from 'features/module/reset-module'
import { SetModuleSettings } from 'features/module/set-module-settings'
import { selectActiveTab } from 'entities/tab'
import { getModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import keyToId from 'shared/lib/key-to-id'
import { moduleReducer, setIsChanged, setSettings } from './model/module-reducer'
import { moduleInitState } from './model/module-reducer'
import css from './ModuleLayout.module.scss'

export const ModuleLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, reducerDispatch] = useReducer(moduleReducer, moduleInitState)
  const [moduleId, setModuleId] = useState<string | null>(null)
  const activeTab = useAppSelector(selectActiveTab)
  const {
    data,
    isLoading,
    isValidating
  } = useSWR(
    moduleId ? `${SETTINGS_URL}${moduleId}` : null,
    () => getModuleSettings(moduleId),
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      revalidateOnMount: true,
    }
  )

  useEffect(() => {
    if (activeTab) {
      setModuleId(keyToId(activeTab.key))
      reducerDispatch(setIsChanged(false))
    }
  }, [activeTab])

  useEffect(() => {
    if (data)
      reducerDispatch(setSettings({ ...data.settings, uid: data.uid }))
  }, [data])

  return (
    <Spin spinning={isLoading || isValidating}>
      <ModuleContext.Provider value={{ state, reducerDispatch }}>
        <div className={css.ModuleLayout}>
          <div className={cn([css.ModuleLayout_header, 'bd-bottom'])}>
            <ResetModule id={moduleId}/>
            <ControlModule id={moduleId} isStarted={data?.started}/>
            <SetModuleSettings id={moduleId} />
          </div>

          {(activeTab && data) && (
            <div className={css.ModuleLayout_content}>
              <div className={'module-content'}>
                {(activeTab.key && state.settings) ? (
                  (keyToId(activeTab.key) === state.settings.uid) && children
                ) : null}
              </div>
            </div>
          )}
        </div>
      </ModuleContext.Provider>
    </Spin>
  )
}
