import React, { useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { BindingChannels } from 'widgets/binding-channels'
import { idTypeSelect, modeSelect, tagTypeSelect } from 'widgets/plugins/opc-ua-client'
import type { OPCClientSettings } from 'widgets/plugins/opc-ua-client'
import type { ChannelsTableData } from 'entities/channel'
import type { DirTreeData } from 'entities/directory'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import filterNums from 'shared/lib/filter-nums'
import omitObject from 'shared/lib/omit-object'
import formValidate from 'shared/lib/form-validate'
import keyToId from 'shared/lib/key-to-id'
import generateInt64 from 'shared/lib/generate-int64'
import AddModal from 'shared/ui/modals/add-modal'
import { FormChannelBinding } from 'shared/ui/form/form-channel-binding'

const { Option } = Select

type OpcObjectFormType = {
  label: string
  description: string
  mode: number
  active: boolean
  type: number
  node_id_id: string
  node_id_ns: number
  node_id_type: number
  core_channel_uid: string
  core_channel_uid_name: string
}

type AddOpcObjectProps = {
  settings: OPCClientSettings
  selectedFolder: DirTreeData | null
}

export const AddOPCObject: FC<AddOpcObjectProps> = ({ settings, selectedFolder }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isBindingOpen, setIsBindingOpen] = useState<boolean>(false)
  const [isBindingActive, setIsBindingActive] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  const onAdd = (values: OpcObjectFormType) => {
    if (settings && selectedFolder) {
      setLoading(true)

      const set = omitObject(settings, ['uid'])

      set.config.objects_list.push({
        ind: generateInt64(),
        object: {
          folder: +keyToId(selectedFolder.key),
          label: values.label,
          description: values.description,
          mode: values.mode,
          active: values.active,
          type: values.type,
          node_id: {
            id: values.node_id_id,
            ns: +values.node_id_ns,
            type: values.node_id_type,
          },
          core_channel_uid: values.core_channel_uid ? values.core_channel_uid : '',
        }
      })

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  const onSaveBindingHandler = (data: Partial<ChannelsTableData>[]) => {
    if (data.length) {
      form.setFieldValue('core_channel_uid', keyToId(String(data[0].key)))
      form.setFieldValue('core_channel_uid_name', data[0].name)
    }
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        disabled={!selectedFolder || selectedFolder.key === 'dir-0'}
        onClick={() => {
          if (
            selectedFolder
            && selectedFolder.key
            && selectedFolder.key !== 'dir-0'
          ) setIsModalOpen(true)
        }}
      />

      <AddModal
        title="объекта"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={() => {
          setIsModalOpen(false)
          form.resetFields()
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
          initialValues={{
            mode: 1,
            type: 1,
            active: true,
            node_id_type: 0,
          }}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onAdd)
          }}
        >
          <Form.Item<OpcObjectFormType>
            label="Имя тега"
            name="label"
            rules={[{ required: true, message: 'Введите имя' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<OpcObjectFormType>
            label="Описание"
            name="description"
          >
            <Input />
          </Form.Item>

          <Form.Item<OpcObjectFormType>
            label="Режим доступа"
            name="mode"
          >
            <Select>
              {Object.entries(modeSelect).map(([key, val], i) => (
                <Option value={val} key={'mode' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<OpcObjectFormType>
            label="Тип тега"
            name="type"
          >
            <Select>
              {Object.entries(tagTypeSelect).map(([key, val], i) => (
                <Option value={val} key={'tag_type' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<OpcObjectFormType>
            label="Использование тега"
            name="active"
          >
            <Select>
              <Option value={true} key={'active1'}>{'true'}</Option>
              <Option value={false} key={'active2'}>{'false'}</Option>
            </Select>
          </Form.Item>

          <Form.Item<OpcObjectFormType>
            label="Тип идентификатора"
            name="node_id_type"
          >
            <Select>
              {Object.entries(idTypeSelect).map(([key, val], i) => (
                <Option value={val} key={'tag_type' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<OpcObjectFormType>
            label="Пространство имён"
            name="node_id_ns"
          >
            <Input onKeyPress={filterNums} />
          </Form.Item>

          <Form.Item<OpcObjectFormType>
            label="Идентификатор"
            name="node_id_id"
          >
            <Input />
          </Form.Item>

          <FormChannelBinding
            key={'core_channel_uid_field'}
            label={'Привязанный канал'}
            name={'core_channel_uid'}
            onClick={() => {
              setIsBindingOpen(true)
              setIsBindingActive(true)
            }}
            onClear={() => {
              form.setFieldValue('core_channel_uid', '')
              form.setFieldValue('core_channel_uid_name', '')
            }}
          />
        </Form>

        {isBindingActive && (
          <BindingChannels
            boundChannels={[]}
            isOpen={isBindingOpen}
            isMultiple={false}
            onSave={onSaveBindingHandler}
            onClose={() => {
              setIsBindingOpen(false)
              setTimeout(() => setIsBindingActive(false), 300)
            }}
          />
        )}
      </AddModal>
    </>
  )
}
