import React, { useState } from 'react'
import { FC } from 'react'
import type { VarItem } from 'shared/types'
import DeleteModal from 'shared/ui/modals/delete-modal'
import type { VarActionProps } from 'shared/ui/table/variables-table'

export const DeleteVar: FC<VarActionProps> = ({ name, variables, varNameField, onEdit }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const onDelete = () => {
    if (variables) {
      const newVars = variables.filter((v: VarItem) => v[varNameField] !== name)

      setLoading(true)

      onEdit(newVars).then(() => {
        setLoading(false)
        setIsModalOpen(false)
      })
    }
  }

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>Удалить</div>

      <DeleteModal
        title="Удаление переменной"
        text={'Удалить переменную'}
        textValue={name}
        open={isModalOpen}
        confirmLoading={loading}
        onDelete={() => onDelete()}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  )
}
