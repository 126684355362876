import React, { useEffect } from 'react'
import type { FC, Dispatch } from 'react'
import { Checkbox, Col, Form, Input, Row, Select, Space } from 'antd'
import { setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleReducerAction } from 'layouts/module-layout'
import { dispatchEvent } from 'shared/lib/custom-events'
import filterNums from 'shared/lib/filter-nums'
import type { MQTTFields, MQTTSettingsType } from '../types'

const { Option } = Select

type MQTTFormProps = {
  settings: MQTTSettingsType
  reducerDispatch: Dispatch<ModuleReducerAction>
  isChanged: boolean
}

export const MQTTForm: FC<MQTTFormProps> = ({ settings, reducerDispatch, isChanged }) => {
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        broker_address: settings.broker_address,
        broker_port: settings.broker_port,
        broker_QoS: settings.broker_QoS,
        use_authorization: settings.use_authorization,
        user_name: settings.user_name,
        user_password: settings.user_password,
      })
    }
  }, [settings])

  useEffect(() => {
    if (isChanged)
      validateForm(values.use_authorization)
  }, [values])

  const validateForm = (ssl: boolean) => {
    const requiredFields = ['broker_address', 'broker_port', 'broker_QoS']
    const fieldsToValidate = ssl
      ? [...requiredFields, 'user_name', 'user_password']
      : requiredFields

    form.validateFields(fieldsToValidate)
      .then(() => reducerDispatch(setIsChanged(true)))
      .catch(() => reducerDispatch(setIsChanged(false)))
  }

  return (
    <Form
      name={'mqtt-form'}
      form={form}
      layout={'vertical'}
      className={'module'}
      initialValues={{ broker_QoS: 0 }}
      onKeyUp={e => {
        if (e.code === 'Enter') dispatchEvent('module:onPressEnter')
      }}
    >
      <Row gutter={[10, 0]} align={'bottom'}>
        <Col span={6}>
          <Form.Item<MQTTFields>
            label="Адрес устройства"
            name="broker_address"
            rules={[{ required: true, message: 'Введите адрес устройства' }]}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  broker_address: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<MQTTFields>
            label="Порт устройства"
            name="broker_port"
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  broker_port: +e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
              onBlur={(e) => {
                if (!e.target.value)
                  form.setFieldValue('broker_port', 1)
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<MQTTFields>
            label="Качество обслуживания"
            name="broker_QoS"
          >
            <Select
              popupMatchSelectWidth={false}
              onChange={(val) => {
                reducerDispatch(setSettings({
                  ...settings,
                  broker_QoS: val
                }))
                reducerDispatch(setIsChanged(true))
              }}
            >
              {[...Array(3)].map((_, i) => (
                <Option value={i} key={'id' + i}>{i}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<MQTTFields>
            name="use_authorization"
            valuePropName="checked"
          >
            <Space>
              Использовать авторизацию
              <Checkbox
                checked={settings.use_authorization}
                onChange={(e) => {
                  reducerDispatch(setSettings({
                    ...settings,
                    use_authorization: e.target.checked
                  }))
                  reducerDispatch(setIsChanged(true))
                }}
              />
            </Space>
          </Form.Item>
        </Col>
      </Row>

      <div style={{ display: settings.use_authorization ? 'block' : 'none' }}>
        <Row gutter={[10, 0]} align={'bottom'}>
          <Col span={6}>
            <Form.Item<MQTTFields>
              label="Логин клиента"
              name="user_name"
              rules={[{ required: true, message: 'Введите логин клиента' }]}
            >
              <Input
                onChange={(e) => {
                  reducerDispatch(setSettings({
                    ...settings,
                    user_name: e.target.value
                  }))
                  reducerDispatch(setIsChanged(true))
                }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item<MQTTFields>
              label="Пароль клиента"
              name="user_password"
              rules={[{ required: true, message: 'Введите пароль клиента' }]}
            >
              <Input
                onChange={(e) => {
                  reducerDispatch(setSettings({
                    ...settings,
                    user_password: e.target.value
                  }))
                  reducerDispatch(setIsChanged(true))
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  )
}
