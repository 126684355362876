import React, { useEffect, useState } from 'react'
import type { FC, Key } from 'react'
import useSWR from 'swr'
import { EditOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Typography } from 'antd'
import { qualitySelect, typesSelect } from 'entities/channel'
import type { Channel, EditChannelType, EditStateType, CreateChannelType } from 'entities/channel'
import { changeChannels, changeChannelsState, getChannels } from 'shared/api/channels'
import { API_URL } from 'shared/config'
import type { CommonObject } from 'shared/types'
import formValidate from 'shared/lib/form-validate'
import EditModal from 'shared/ui/modals/edit-modal'

const { Option } = Select
const { Link } = Typography

type EditChannelsType = {
  selectedRows: Key[]
  onSave?: () => void
}

export const EditChannels: FC<EditChannelsType> = ({ selectedRows }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [touchedFields, setTouchedFields] = useState<CommonObject<boolean>>({})
  const [selectedChannels, setSelectedChannels] = useState<Channel[]>([])
  const [form] = Form.useForm()
  const { data: channelsData } = useSWR(
    isModalOpen ? `${API_URL}/channels` : null,
    getChannels
  )

  useEffect(() => {
    if (channelsData && channelsData.channels.length && selectedRows.length) {
      // Объекты выбранных каналов
      setSelectedChannels(() => (
        channelsData.channels.filter(ch => (
          selectedRows.includes('channel-' + ch.uid)
        ))
      ))
    }
  }, [channelsData, selectedRows])

  const onSave = async (values: CreateChannelType) => {
    setLoading(true)
    const fields: EditChannelType[] = []
    const state: EditStateType[] = []
    // Соотносим измененные поля со значениями полей формы
    const fieldsToSave = Object.keys(values)
      .reduce<CommonObject<string | number | undefined>>((acc, curr) => {
        if (touchedFields[curr])
          acc[curr] = values[curr as keyof CreateChannelType]

        return acc
      }, {})

    // Отдельные массивы для параметров и состояния
    selectedChannels.forEach(item => {
      fields.push({
        uid: item.uid,
        name: fieldsToSave.name !== undefined ? String(fieldsToSave.name) : item.name,
        type: fieldsToSave.type !== undefined ? +fieldsToSave.type : item.type,
        dir: item.dir,
        group: fieldsToSave.group !== undefined ? String(fieldsToSave.group) : item.group,
        description: fieldsToSave.description !== undefined ? String(fieldsToSave.description) : item.description,
      })

      if (fieldsToSave.value !== undefined && fieldsToSave.quality === undefined) {
        state.push({
          uid: item.uid,
          value: String(fieldsToSave.value),
          quality: 1
        })
      } else if (fieldsToSave.value !== undefined || fieldsToSave.quality !== undefined) {
        state.push({
          uid: item.uid,
          value: fieldsToSave.value !== undefined ? String(fieldsToSave.value) : item.value,
          quality: fieldsToSave.quality !== undefined ? +fieldsToSave.quality : item.quality,
        })
      }
    })

    try {
      await changeChannels(fields)
    } catch (err) {
      console.error(err)
    }

    if (state.length) {
      try {
        await changeChannelsState(state)
      } catch (err) {
        console.error(err)
      }
    }

    clearState()
  }

  const resetField = (fieldName: string, value?: string | number) => {
    form.setFieldValue(fieldName, value ? value : 'Несколько значений')
    setTouchedFields(prev => {
      const values: CommonObject<boolean> = {}

      Object.keys(prev).forEach(key => {
        if (key !== fieldName)
          values[key] = prev[key]
      })

      return values
    })
  }

  const selectField = (fieldName: string, isSelect?: boolean) => {
    if (!touchedFields[fieldName]) {
      if (!isSelect)
        form.setFieldValue(fieldName, '')

      setTouchedFields(prev => ({ ...prev, [fieldName]: true }))
    }
  }

  const clearState = () => {
    setIsModalOpen(false)
    form.resetFields()
    setTouchedFields({})
    setLoading(false)
  }

  return (
    <>
      <Button
        type="primary"
        icon={<EditOutlined />}
        size={'small'}
        disabled={!selectedRows.length}
        onClick={() => setIsModalOpen(true)}
      />

      <EditModal
        title="каналов"
        open={isModalOpen}
        confirmLoading={loading}
        onSave={() => formValidate(form, onSave)}
        onCancel={() => clearState()}
      >
        <Form
          form={form}
          layout={'vertical'}
          initialValues={{
            name: 'Несколько значений',
            group: 'Несколько значений',
            value: 'Несколько значений',
            description: 'Несколько значений',
            type: 'select',
            quality: 'select',
          }}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onSave)
          }}
        >
          <Form.Item<CreateChannelType>
            label="Имя канала"
            rules={[{ required: true, message: 'Введите имя канала' }]}
          >
            <Form.Item name="name" noStyle>
              <Input onFocus={() => selectField('name')} />
            </Form.Item>

            { touchedFields.name && <Link onClick={() => resetField('name')}>Сбросить</Link> }
          </Form.Item>

          <Form.Item<CreateChannelType>
            label="Тип канала"
          >
            <Form.Item name="type" noStyle>
              <Select onSelect={() => selectField('type', true)} >
                <Option value={'select'} key={'select'} disabled>Несколько значений</Option>
                {Object.entries(typesSelect).map(([key, val], i) => (
                  <Option value={val} key={'type' + i}>{key}</Option>
                ))}
              </Select>
            </Form.Item>

            { touchedFields.type && <Link onClick={() => resetField('type', 0)}>Сбросить</Link> }
          </Form.Item>

          <Form.Item<CreateChannelType>
            label="Группы"
          >
            <Form.Item name="group" noStyle>
              <Input onFocus={() => selectField('group')} />
            </Form.Item>

            { touchedFields.group && <Link onClick={() => resetField('group')}>Сбросить</Link> }
          </Form.Item>

          <Form.Item<CreateChannelType>
            label="Значение"
          >
            <Form.Item name="value" noStyle>
              <Input onFocus={() => selectField('value')} />
            </Form.Item>

            { touchedFields.value && <Link onClick={() => resetField('value')}>Сбросить</Link> }
          </Form.Item>

          <Form.Item<CreateChannelType>
            label="Качество"
          >
            <Form.Item name="quality" noStyle>
              <Select onSelect={() => selectField('quality', true)}>
                <Option value={'select'} key={'select'} disabled>Несколько значений</Option>
                {Object.entries(qualitySelect).map(([key, val], i) => (
                  <Option value={val} key={'type' + i}>{key}</Option>
                ))}
              </Select>
            </Form.Item>

            { touchedFields.quality && <Link onClick={() => resetField('quality', 0)}>Сбросить</Link> }
          </Form.Item>

          <Form.Item<CreateChannelType>
            label="Описание"
          >
            <Form.Item name="description" noStyle>
              <Input onFocus={() => selectField('description')} />
            </Form.Item>

            { touchedFields.description && <Link onClick={() => resetField('description')}>Сбросить</Link> }
          </Form.Item>
        </Form>
      </EditModal>
    </>
  )
}
