export type WialonRetranslatorSettings = {
  uid: string
  ip_addr: string
  ip_port: number
  polling_objects_period: number
  objects_list: WialonRetranslatorObject[]
}

export type WialonRetranslatorObject = {
  id: number
  wid: string
  lat_channel: string
  lon_channel: string
  alt_channel: string
  azm_channel: string
  spd_channel: string
}

export const WRObjectChannels = [
  {
    label: 'Канал широты',
    name: 'lat_channel'
  },
  {
    label: 'Канал долготы',
    name: 'lon_channel'
  },
  {
    label: 'Канал высоты',
    name: 'alt_channel'
  },
  {
    label: 'Канал азимута',
    name: 'azm_channel'
  },
  {
    label: 'Канал скорости',
    name: 'spd_channel'
  },
]

export type WialonRetranslatorFields = Partial<WialonRetranslatorSettings>
export type AddWRFields = Partial<WialonRetranslatorObject>
