import type { Key } from 'react'
import type { Directory } from 'entities/directory'
import { API_URL } from 'shared/config'
import httpHandler from 'shared/lib/api/http-handler'

export const ROOT_DIR_URL = `${API_URL}/directories/root`
export const DIR_URL = `${API_URL}/directories`

export async function getDirectoriesRoot(): Promise<Directory[]> {
  const data = await (await httpHandler(ROOT_DIR_URL)).json()
  return data.directories
}

export async function getDirectories(params?: string): Promise<Directory[]> {
  const data = await (await httpHandler(params ? DIR_URL + params : DIR_URL)).json()
  return data.directories
}

export async function deleteDirectories(directories: Key[]) {
  if (directories.length) {
    return await httpHandler(DIR_URL, {
      method: 'DELETE',
      body: JSON.stringify({ directories }),
    })
  }
}

export async function createDirectory(parentID: string, name: string) {
  const body = JSON.stringify({
    directories: [{
      dir: parentID,
      name: name
    }]
  })

  return await httpHandler(DIR_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
}

export async function changeDirectoryName(id: string, name: string) {
  const body = JSON.stringify({
    directories: [{ uid: id, name: name }]
  })

  return await httpHandler(`${DIR_URL}/parameters`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
}

export async function changeDirectoryParent(id: string, parentId: string) {
  const body = JSON.stringify({
    directories: { uid: id, dir: parentId }
  })

  return await httpHandler(`${DIR_URL}/placement`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
}

