import React, { useEffect } from 'react'
import type { FC } from 'react'
import { Col, Form, Input, Row } from 'antd'
import { setIsChanged, setSettings } from 'layouts/module-layout'
import { dispatchEvent } from 'shared/lib/custom-events'
import filterNums from 'shared/lib/filter-nums'
import type { NotificationFormProps, NotificationsFields } from '../types'

export const NotificationForm: FC<NotificationFormProps> = ({ settings, isChanged, reducerDispatch }) => {
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        ip: settings.ip,
        port: settings.port,
        url: settings.url,
      })
    }
  }, [settings])

  useEffect(() => {
    if (isChanged)
      form.validateFields()
        .then(() => reducerDispatch(setIsChanged(true)))
        .catch(() => reducerDispatch(setIsChanged(false)))
  }, [values])

  return (
    <>
      <div className={'content-wrapper'}>
        <Form
          name={'notifications-form'}
          form={form}
          layout={'vertical'}
          className={'module'}
          onKeyUp={e => {
            if (e.code === 'Enter') dispatchEvent('module:onPressEnter')
          }}
        >
          <Row gutter={[10, 0]} align={'bottom'}>
            <Col span={6}>
              <Form.Item<NotificationsFields>
                label="Адрес интерфейса"
                name="ip"
                rules={[{ required: true, message: 'Введите адрес интерфейса' }]}
              >
                <Input
                  onChange={(e) => {
                    reducerDispatch(setSettings({
                      ...settings,
                      ip: e.target.value
                    }))
                    reducerDispatch(setIsChanged(true))
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item<NotificationsFields>
                label="Порт сервиса"
                name="port"
              >
                <Input
                  onKeyPress={filterNums}
                  onChange={(e) => {
                    reducerDispatch(setSettings({
                      ...settings,
                      port: +e.target.value
                    }))
                    reducerDispatch(setIsChanged(true))
                  }}
                  onBlur={(e) => {
                    if (!e.target.value)
                      form.setFieldValue('port', 8000)
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item<NotificationsFields>
                label="Адрес ресурса"
                name="url"
                rules={[{ required: true, message: 'Введите адрес ресурса' }]}
              >
                <Input
                  onChange={(e) => {
                    reducerDispatch(setSettings({
                      ...settings,
                      url: e.target.value
                    }))
                    reducerDispatch(setIsChanged(true))
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}
