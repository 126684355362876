import React, { useContext, useRef } from 'react'
import type { FC } from 'react'
import { Flex } from 'antd'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import AddWRObject from 'features/objects/add-wr-object'
import { RequestsTable } from 'shared/ui/table/requests-table'
import WialonRetranslatorForm from './wialon-retranslator-form'
import type { WialonRetranslatorSettings } from '../types'
import { COLUMNS } from '../config'

const WialonRetranslator: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<WialonRetranslatorSettings>>(ModuleContext)
  const parentRef = useRef<HTMLDivElement>(null)

  return (
    <Flex vertical style={{ height: '100%' }}>
      <div className={'content-wrapper'}>
        <WialonRetranslatorForm />
      </div>

      <div ref={parentRef}>
        <RequestsTable
          settings={settings}
          reducerDispatch={reducerDispatch}
          itemsName={'objects_list'}
          itemPrefix={'object-'}
          deleteText={'Удалить объект'}
          isChanged={isChanged}
          columns={COLUMNS}
          headerComponents={[<AddWRObject key={'WRObjectChannels'} settings={settings} />]}
        />
      </div>
    </Flex>
  )
}

export default WialonRetranslator
