const fileSaver = (content: string, fileName: string, type: string = 'text/plain') => {
  const link = document.createElement('a')
  const file = new Blob([content], { type })
  link.href = URL.createObjectURL(file)
  link.download = fileName
  link.click()
  URL.revokeObjectURL(link.href)
}

export default fileSaver
