import React, { useEffect } from 'react'
import type { FC } from 'react'
import { Checkbox, Col, Form, Input, Row, Space } from 'antd'
import filterNums from 'shared/lib/filter-nums'
import { dispatchEvent } from 'shared/lib/custom-events'
import type { ServerFields, ServerSettingsType } from './types'

const { TextArea } = Input

type ServerFormProps = {
  settings: ServerSettingsType
  isChanged: boolean
  onChange: (field: string, val: string | number | boolean) => void
  onValidate: (isValid: boolean) => void
}

export const ServerForm: FC<ServerFormProps> = ({ settings, isChanged, onChange, onValidate }) => {
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        ip: settings.ip,
        port: String(settings.port),
        threads: settings.threads,
        ssl: settings.use_ssl,
        cert: atob(settings.ssl_cert),
        key: atob(settings.ssl_key),
        dh: atob(settings.ssl_dh)
      })
    }
  }, [settings])

  useEffect(() => {
    if (isChanged)
      validateForm(values.ssl)
  }, [values])

  const validateForm = (ssl: boolean) => {
    const fieldsToValidate = ssl ? ['ip', 'port', 'cert', 'key'] : ['ip', 'port']

    form.validateFields(fieldsToValidate)
      .then(() => onValidate(true))
      .catch(() => onValidate(false))
  }

  return (
    <Form
      name={'http-form'}
      form={form}
      layout={'vertical'}
      className={'module'}
      onKeyUp={e => {
        if (e.code === 'Enter') dispatchEvent('module:onPressEnter')
      }}
    >
      <Row gutter={[10, 0]} align={'bottom'}>
        <Col span={6}>
          <Form.Item<ServerFields>
            label="IP-адрес"
            name="ip"
            rules={[{ required: true, message: 'Введите ip адрес' }]}
          >
            <Input
              onChange={(e) => onChange('ip', e.target.value)}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ServerFields>
            label="Порт подключения"
            name="port"
            className={'port'}
            rules={[
              { min: 2, message: 'Введите порт' },
              { required: true, message: 'Введите порт' }
            ]}
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => onChange('port', +e.target.value)}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ServerFields>
            label="Количество потоков сервера http"
            name="threads"
            className={'threads'}
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => onChange('threads', +e.target.value)}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ServerFields>
            name="ssl"
            valuePropName="checked"
          >
            <Space>
              Использовать SSL
              <Checkbox
                checked={settings.use_ssl}
                onChange={(e) => onChange('use_ssl', e.target.checked)}
              />
            </Space>
          </Form.Item>
        </Col>
      </Row>

      <div style={{ display: settings.use_ssl ? 'block' : 'none' }}>
        <Row gutter={[10, 0]} align={'bottom'}>
          <Col span={8}>
            <Form.Item<ServerFields>
              name="cert"
              label="Сертификат:"
              className={'textarea'}
              rules={[{ required: true, message: 'Введите cертификат' }]}
            >
              <TextArea
                style={{ height: '200px', resize: 'none' }}
                onChange={(e) => onChange('ssl_cert', btoa(e.target.value))}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item<ServerFields>
              name="key"
              label="Ключ:"
              className={'textarea'}
              rules={[{ required: true, message: 'Введите ключ' }]}
            >
              <TextArea
                style={{ height: '200px', resize: 'none' }}
                onChange={(e) => onChange('ssl_key', btoa(e.target.value))}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item<ServerFields>
              name="dh"
              label="DH параметры:"
              className={'textarea'}
            >
              <TextArea
                style={{ height: '200px', resize: 'none' }}
                onChange={(e) => onChange('ssl_dh', btoa(e.target.value))}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  )
}
