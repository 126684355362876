import React, { useContext, useEffect, useState } from 'react'
import type { FC } from 'react'
import { mutate } from 'swr'
import { Col, Flex, Form, Input, Row } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { ModuleCommands } from 'features/module/commands'
import { AddMFSSObject } from 'features/mfss/add-mfss-object'
import type { ChannelsTableData } from 'entities/channel'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import { dispatchEvent } from 'shared/lib/custom-events'
import createSearchChannelsArr from 'shared/lib/objects/create-search-channels-arr'
import getObjectsToSave from 'shared/lib/objects/get-objects-to-save'
import filterNums from 'shared/lib/filter-nums'
import keyToId from 'shared/lib/key-to-id'
import omitObject from 'shared/lib/omit-object'
import { ModuleObjects } from 'shared/ui/module-objects'
import { ChannelsList } from 'shared/ui/channels-list'
import type { MFSSFields, MFSSSettings } from './types'
import { COLUMNS, commands } from './config'
import { createTableData } from './model/create-table-data'

export const MFSS: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<MFSSSettings>>(ModuleContext)
  const [isChannelsLoading, setIsChannelsLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        ip: settings.ip,
        port: settings.port,
      })
    }
  }, [settings])

  useEffect(() => {
    if (isChanged) {
      form.validateFields({ validateOnly: true })
        .then(
          () => reducerDispatch(setIsChanged(true)),
          () => reducerDispatch(setIsChanged(false)),
        )
    }
  }, [values])

  const onSaveChannelsHandler = async (data: Partial<ChannelsTableData>[]) => {
    setIsChannelsLoading(true)
    const newChannels = data.map(d => keyToId(String(d.key)))

    if (settings) {
      const set = omitObject(settings, ['uid'])

      set.channels_event = newChannels

      return setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setIsChannelsLoading(false)
        })
    }
  }

  return (
    <Flex vertical style={{ height: '100%' }}>
      <div className={'content-wrapper bd-bottom'}>
        <ModuleCommands
          id={settings.uid}
          commands={commands}
        />
      </div>

      <div className={'content-wrapper'}>
        <Form
          layout={'vertical'}
          form={form}
          className={'module'}
          onKeyUp={e => {
            if (e.code === 'Enter' && isChanged) dispatchEvent('module:onPressEnter')
          }}
        >
          <Row gutter={[10, 0]} align={'bottom'}>
            <Col span={6}>
              <Form.Item<MFSSFields>
                label={'Адрес сервера'}
                name={'ip'}
                rules={[{ required: true, message: 'Введите адрес сервера' }]}
              >
                <Input
                  onChange={(e) => {
                    reducerDispatch(setSettings({
                      ...settings,
                      ip: e.target.value
                    }))
                    reducerDispatch(setIsChanged(true))
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item<MFSSFields>
                label={'Порт'}
                name={'port'}
              >
                <Input
                  onKeyPress={filterNums}
                  onChange={(e) => {
                    reducerDispatch(setSettings({
                      ...settings,
                      port: (+e.target.value) ? +e.target.value : 1
                    }))
                    reducerDispatch(setIsChanged(true))
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <ModuleObjects
        settings={settings}
        reducerDispatch={reducerDispatch}
        columns={COLUMNS}
        objectsChannelFields={[{ label: 'Привязанный канал', name: 'core_channel_uid' }]}
        AddComponent={AddMFSSObject}
        createChannelsArr={createSearchChannelsArr}
        createTableData={createTableData}
        getObjectsToSave={getObjectsToSave}
      />

      <ChannelsList
        channels={settings.channels_event}
        title={'Каналы событий'}
        isLoading={isChannelsLoading}
        onSave={onSaveChannelsHandler}
      />
    </Flex>
  )
}
