import { ChannelsTableData, EditChannelType } from '../types'
import keyToId from 'shared/lib/key-to-id'
import type { DirTreeData } from 'entities/directory'

export const createEditedItems = (prevData: EditChannelType[], newItem: ChannelsTableData, activeDir: DirTreeData | null) => {
  const newData = [...prevData]
  const objToSave: EditChannelType = {
    uid: keyToId(String(newItem.key)),
    name: newItem.name,
    type: newItem.type,
    value: newItem.value,
    quality: newItem.quality,
    dir: activeDir ? keyToId(activeDir.key) : '',
    group: newItem.group,
    description: newItem.description,
    isValueChanged: newItem.changed.has('value'),
    isQualityChanged: newItem.changed.has('quality'),
  }
  const currIndex = newData.findIndex((item) => item.uid === objToSave.uid)

  if (!newData.length || currIndex === -1)
    newData.push(objToSave)
  else
    newData[currIndex] = objToSave

  return newData
}
