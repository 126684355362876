import React, { useContext, useEffect, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Checkbox, Col, Form, Input, Row, Select, Space } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { BindingChannels } from 'widgets/binding-channels'
import type { ChannelsTableData, ChannelItem } from 'entities/channel'
import { useChannels } from 'shared/hooks/use-channels'
import filterNums from 'shared/lib/filter-nums'
import omitObject from 'shared/lib/omit-object'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import keyToId from 'shared/lib/key-to-id'
import { dispatchEvent } from 'shared/lib/custom-events'
import { idSelect } from './config'
import type { OPCServerSettingsType } from './types'

const { Option } = Select

export const OpcUaServer: FC = () => {
  const {
    state: { settings, isChanged },
    reducerDispatch
  } = useContext<ModuleContextType<OPCServerSettingsType>>(ModuleContext)
  const [isSecure, setIsSecure] = useState<boolean>(false)
  const [channelsArr, setChannelsArr] = useState<string[] | null>(null)
  const [channels, setChannels] = useState<ChannelItem[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isModalActive, setIsModalActive] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)
  const { data: channelsData } = useChannels(channelsArr)

  useEffect(() => {
    if (settings) {
      setIsSecure(settings.security)

      if (settings.channels.length)
        setChannelsArr(settings.channels)
      else
        setChannelsArr(null)

      form.setFieldsValue({
        host: settings.host,
        port: String(settings.port),
        idType: settings.node_id_type,
        username: settings.login,
        password: settings.password
      })
    }
  }, [settings])

  useEffect(() => {
    if (isChanged)
      validateForm(values.security)
  }, [values])

  useEffect(() => {
    if (channelsData) {
      setChannels(channelsData.channels.map(ch => ({ key: 'channel-' + ch.uid, name: ch.name })))
    }
  }, [channelsData])

  const validateForm = (security: boolean) => {
    const fieldsToValidate = security
      ? ['host', 'port', 'username', 'password']
      : ['host', 'port']

    form.validateFields(fieldsToValidate)
      .then(() => reducerDispatch(setIsChanged(true)))
      .catch(() => reducerDispatch(setIsChanged(false)))
  }

  const onSaveHandler = async (data: Partial<ChannelsTableData>[]) => {
    if (settings) {
      setLoading(true)
      const set = omitObject(settings, ['uid'])

      set.channels = data.map(ch => keyToId(String(ch.key)))

      return setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
        })
    }
  }

  return (
    <div className={'content-wrapper'}>
      <Button
        type="primary"
        size={'small'}
        style={{ marginBottom: '10px' }}
        onClick={() => {
          setIsModalOpen(true)
          setIsModalActive(true)
        }}
      >
        Привязать каналы
      </Button>

      <Form
        form={form}
        layout={'vertical'}
        className={'module'}
        onKeyUp={e => {
          if (e.code === 'Enter') dispatchEvent('module:onPressEnter')
        }}
      >
        <Row gutter={[10, 0]} align={'bottom'}>
          <Col span={6}>
            <Form.Item
              label={'Хост'}
              name={'host'}
              className={'host'}
              rules={[{ required: true, message: 'Введите ip адрес' }]}
            >
              <Input
                onChange={(e) => {
                  reducerDispatch(setSettings({
                    ...settings,
                    host: e.target.value
                  }))
                  reducerDispatch(setIsChanged(true))
                }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label={'Порт'}
              name={'port'}
              className={'port'}
              rules={[
                { min: 2, message: 'Введите порт' },
                { required: true, message: 'Введите порт' }
              ]}
            >
              <Input
                onKeyPress={filterNums}
                onChange={(e) => {
                  reducerDispatch(setSettings({
                    ...settings,
                    port: +e.target.value
                  }))
                  reducerDispatch(setIsChanged(true))
                }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label={'Тип идентификатора'}
              name={'idType'}
            >
              <Select
                onChange={(val) => {
                  reducerDispatch(setSettings({
                    ...settings,
                    node_id_type: val
                  }))
                  reducerDispatch(setIsChanged(true))
                }}
              >
                {Object.entries(idSelect).map(([key, val], i) => (
                  <Option value={val} key={'id' + i}>{key}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label={'security'}
              name="security"
              valuePropName={'checked'}
              className={'checkbox'}
            >
              <Space>
                Логин/Пароль:
                <Checkbox
                  checked={isSecure}
                  onChange={(e) => {
                    reducerDispatch(setSettings({
                      ...settings,
                      security: e.target.checked
                    }))
                    reducerDispatch(setIsChanged(true))
                  }}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>

        <div style={{ display: settings.security ? 'block' : 'none' }}>
          <Row gutter={[10, 0]} align={'bottom'}>
            <Col span={6}>
              <Form.Item
                label={'Логин:'}
                name={'username'}
                rules={[{ required: true, message: 'Введите логин' }]}
              >
                <Input
                  onChange={(e) => {
                    if (isSecure) {
                      reducerDispatch(setSettings({
                        ...settings,
                        login: e.target.value
                      }))
                      reducerDispatch(setIsChanged(true))
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={'Пароль:'}
                name={'password'}
                rules={[{ required: true, message: 'Введите пароль' }]}
              >
                <Input
                  type={'password'}
                  onChange={(e) => {
                    if (isSecure) {
                      reducerDispatch(setSettings({
                        ...settings,
                        password: e.target.value
                      }))
                      reducerDispatch(setIsChanged(true))
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>

      {isModalActive && (
        <BindingChannels
          boundChannels={channels}
          isLoading={loading}
          isOpen={isModalOpen}
          onSave={onSaveHandler}
          onClose={() => {
            setIsModalOpen(false)
            setTimeout(() => setIsModalActive(false), 300)
          }}
        />
      )}
    </div>
  )
}
