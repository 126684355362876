import React, { useContext, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { operationsSelect } from 'widgets/plugins/snmp'
import type { SNMPRequest, SNMPSettingsType } from 'widgets/plugins/snmp'
import { BindingChannels } from 'widgets/binding-channels'
import type { ChannelItem, ChannelsTableData } from 'entities/channel'
import omitObject from 'shared/lib/omit-object'
import formValidate from 'shared/lib/form-validate'
import keyToId from 'shared/lib/key-to-id'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import AddModal from 'shared/ui/modals/add-modal'
import { FormChannelBinding } from 'shared/ui/form/form-channel-binding'

const { Option } = Select

type FormFields = {
  OID: string
  operations: string
  result_channel: string
  result_channel_name: string
}

export const AddRequest: FC = () => {
  const { state: { settings } } = useContext<ModuleContextType<SNMPSettingsType>>(ModuleContext)
  const [channelData, setChannelData] = useState<ChannelItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isBindingActive, setIsBindingActive] = useState<boolean>(false)
  const [isBindingOpen, setIsBindingOpen] = useState<boolean>(false)
  const [form] = Form.useForm()
  const { mutate } = useSWRConfig()

  const onSave = (values: FormFields) => {
    if (settings) {
      const set = omitObject(settings, ['uid'])

      set.requests.push({
        id: 0,
        OID: values.OID,
        operations: values.operations,
        result_channel: values.result_channel,
      })

      set.requests = set.requests.map((n: SNMPRequest, i: number) => ({ ...n, id: i + 1 }))

      setLoading(true)

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  const onSaveBindingHandler = (data: Partial<ChannelsTableData>[]) => {
    if (data.length) {
      form.setFieldValue('result_channel', keyToId(String(data[0].key)))
      form.setFieldValue('result_channel_name', data[0].name)
      setChannelData([{ key: data[0].key || '', name: data[0].name || '' }])
    }
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        onClick={() => setIsModalOpen(true)}
      />

      <AddModal
        title="идентификатора"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onSave)}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          layout={'vertical'}
          initialValues={{ operations: 'Get' }}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onSave)
          }}
        >
          <Form.Item
            label="Идентификатор объекта"
            name="OID"
            rules={[{ required: true, message: 'Введите идентификатор объекта' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Тип запроса для агента"
            name="operations"
          >
            <Select popupMatchSelectWidth={false}>
              {Object.entries(operationsSelect).map(([key, val], i) => (
                <Option value={val} key={'id' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>

          <FormChannelBinding
            key={'result_channel_field'}
            label={'Канал отображения результата опроса'}
            name={'result_channel'}
            onClick={() => {
              setIsBindingOpen(true)
              setIsBindingActive(true)
            }}
            onClear={() => {
              form.setFieldValue('result_channel', '')
              form.setFieldValue('result_channel_name', '')
            }}
          />
        </Form>

        {isBindingActive && (
          <BindingChannels
            boundChannels={channelData}
            isOpen={isBindingOpen}
            isMultiple={false}
            onSave={onSaveBindingHandler}
            onClose={() => {
              setIsBindingOpen(false)
              setIsBindingActive(false)
            }}
          />
        )}
      </AddModal>
    </>
  )
}
