import React, { useEffect, useRef, useState } from 'react'
import type { FC } from 'react'
import { mutate } from 'swr'
import cn from 'classnames'
import { Button, Modal, Space } from 'antd'
import { ExportOutlined } from '@ant-design/icons'
import CodeMirror from '@uiw/react-codemirror'
import { langs } from '@uiw/codemirror-extensions-langs'
import { xcodeLight } from '@uiw/codemirror-theme-xcode'
import type { JSONSettings, JSONHandler } from 'widgets/plugins/json-parser'
import type { VarItem } from 'shared/types'
import omitObject from 'shared/lib/omit-object'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import { VariablesTable } from 'shared/ui/table/variables-table'

type EditJSONHandlerProps = {
  settings: JSONSettings
  handlerId: number
}

export const EditJSONHandler: FC<EditJSONHandlerProps> = ({ settings, handlerId }) => {
  const [handlerData, setHandlerData] = useState<JSONHandler>()
  const [template, setTemplate] = useState('')
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [isVarsLoading, setIsVarsLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const modalRef = useRef(null)

  useEffect(() => {
    if (handlerId)
      setHandlerData(() => (
        settings.handlers.find(handler => handler.id === handlerId)
      ))
  }, [handlerId, settings])

  useEffect(() => {
    if (handlerData)
      setTemplate(decodeURIComponent(handlerData.template))
  }, [handlerData])

  const onSaveVarsHandler = async (vars: VarItem[]) => {
    if (settings) {
      setHandlerData((prev) => {
        if (prev) {
          return { ...prev, aliases: vars }
        }
      })
    }
  }

  const onSaveModalHandler = () => {
    if (settings) {
      setConfirmLoading(true)

      const set = omitObject(settings, ['uid'])

      set.handlers = settings.handlers.map(handler => (
        (handler.id === handlerId)
          ? {
            ...handler,
            template: encodeURIComponent(template),
            aliases: handlerData ? handlerData.aliases : []
          } : handler
      ))

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setIsVarsLoading(false)
          setConfirmLoading(false)
          setIsModalOpen(false)
        })
    }
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Space>
          <Button
            type="primary"
            icon={<ExportOutlined />}
            size={'small'}
            onClick={() => setIsModalOpen(true)}
          />
        </Space>
      </div>

      <Modal
        title={'Редактирование обработчика'}
        open={isModalOpen}
        onOk={onSaveModalHandler}
        width={1200}
        onCancel={() => setIsModalOpen(false)}
        confirmLoading={confirmLoading}
        cancelText={'Отмена'}
        okText={'Сохранить'}
        forceRender
      >
        <div
          className={'modal-with-code'}
          ref={modalRef}
        >
          <div className={cn(['modal-with-code__item', 'bd-bottom', 'bd-left', 'bd-top'])}>
            <CodeMirror
              value={template}
              extensions={[langs.json()]}
              theme={xcodeLight}
              onChange={(val: string) => setTemplate(val)}
            />
          </div>

          <div
            className={cn([
              'modal-with-code__item',
              'modal-with-code__table',
              'bd-left',
              'bd-top',
            ])}
          >
            <VariablesTable
              parentRef={modalRef}
              variables={handlerData ? Array.from(handlerData.aliases) : []}
              varNameField={'alias'}
              isLoading={isVarsLoading}
              onSave={onSaveVarsHandler}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
