import React, { useContext, useEffect } from 'react'
import type { FC } from 'react'
import { Form, Col, Input, Row, Select } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { flowSelect, paritySelect } from 'widgets/plugins/modbus/config'
import filterNums from 'shared/lib/filter-nums'
import { dispatchEvent } from 'shared/lib/custom-events'
import type { ModbusRTUFields, ModbusRTUSettingsType } from '../../types'

const { Option } = Select

export const RtuForm: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<ModbusRTUSettingsType>>(ModuleContext)
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        port: settings.port,
        baudrate: settings.baudrate,
        data: settings.data,
        stop: settings.stop,
        parity: settings.parity,
        flow: settings.flow,
        nb: settings.nb,
      })
    }
  }, [settings])

  useEffect(() => {
    form.validateFields({ validateOnly: true })
      .then(
        () => reducerDispatch(setIsChanged(isChanged)),
        () => reducerDispatch(setIsChanged(false)),
      )
  }, [values])

  return (
    <Form
      layout={'vertical'}
      form={form}
      className={'module'}
      onKeyUp={e => {
        if (e.code === 'Enter' && isChanged) dispatchEvent('module:onPressEnter')
      }}
    >
      <Row gutter={[10, 0]} align={'top'}>
        <Col span={6}>
          <Form.Item<ModbusRTUFields>
            label={'Порт'}
            name={'port'}
            rules={[{
              pattern: /^([\w/.]+)$/g,
              message: 'Строка может содержать латинские буквы, цифры, знак "/" и точку'
            }]
            }
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  port: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ModbusRTUFields>
            label={'Скорость последовательного порта'}
            name={'baudrate'}
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  baudrate: +e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ModbusRTUFields>
            label={'Количество бит данных'}
            name={'data'}
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  data: +e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ModbusRTUFields>
            label={'Количество стоповых бит'}
            name={'stop'}
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  stop: +e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[10, 0]} align={'bottom'}>
        <Col span={6}>
          <Form.Item<ModbusRTUFields>
            label={'Бит чётности'}
            name={'parity'}
          >
            <Select
              popupMatchSelectWidth={false}
              onChange={(val) => {
                reducerDispatch(setSettings({
                  ...settings,
                  parity: val
                }))
                reducerDispatch(setIsChanged(true))
              }}
            >
              {Object.entries(paritySelect).map(([key, val], i) => (
                <Option value={val} key={'id' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ModbusRTUFields>
            label={'Аппаратный контроль потока'}
            name={'flow'}
          >
            <Select
              popupMatchSelectWidth={false}
              onChange={(val) => {
                reducerDispatch(setSettings({
                  ...settings,
                  flow: val
                }))
                reducerDispatch(setIsChanged(true))
              }}
            >
              {Object.entries(flowSelect).map(([key, val], i) => (
                <Option value={val} key={'id' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ModbusRTUFields>
            label={'Количество байт ожидания между пакетами'}
            name={'nb'}
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  nb: +e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
