import React, { useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { BindingChannels } from 'widgets/binding-channels'
import type { ChannelsTableData } from 'entities/channel'
import type { DirTreeData } from 'entities/directory'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import omitObject from 'shared/lib/omit-object'
import keyToId from 'shared/lib/key-to-id'
import formValidate from 'shared/lib/form-validate'
import generateInt64 from 'shared/lib/generate-int64'
import type { ObjectType, ObjectsFieldsType, AddObjectFieldsType, ObjectSettings } from 'shared/ui/module-objects'
import AddModal from 'shared/ui/modals/add-modal'
import { FormChannelBinding } from 'shared/ui/form/form-channel-binding'

type AddObjectPropsType = {
  settings: ObjectSettings
  selectedFolder: DirTreeData | null
  objectsChannelFields: ObjectsFieldsType<ObjectType>[]
}

export const AddPosObject: FC<AddObjectPropsType> = ({ settings, selectedFolder, objectsChannelFields }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isBindingOpen, setIsBindingOpen] = useState<boolean>(false)
  const [isBindingActive, setIsBindingActive] = useState<boolean>(false)
  const [activeChannelField, setActiveChannelField] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  const onAdd = (values: AddObjectFieldsType) => {
    if (settings && selectedFolder) {
      setLoading(true)

      const set = omitObject(settings, ['uid'])

      set.config.objects_list.push({
        ind: generateInt64(),
        object: objectsChannelFields.reduce<ObjectType>((acc, curr) => {
          acc[curr.name] = values[curr.name] ? values[curr.name] : ''

          return acc
        }, {
          folder: +keyToId(selectedFolder.key),
          label: values.label,
        } as ObjectType)
      })

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  const onSaveBindingHandler = (data: Partial<ChannelsTableData>[]) => {
    if (data.length) {
      form.setFieldValue(`${activeChannelField}`, keyToId(String(data[0].key)))
      form.setFieldValue(`${activeChannelField}_name`, data[0].name)
    }
    setActiveChannelField('')
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        disabled={!selectedFolder || selectedFolder.key === 'dir-0'}
        onClick={() => {
          if (
            selectedFolder
            && selectedFolder.key
            && selectedFolder.key !== 'dir-0'
          ) setIsModalOpen(true)
        }}
      />

      <AddModal
        title="объекта"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={() => {
          setIsModalOpen(false)
          form.resetFields()
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onAdd)
          }}
        >
          <Form.Item<AddObjectFieldsType>
            label="Название"
            name="label"
            rules={[{ required: true, message: 'Введите название' }]}
          >
            <Input />
          </Form.Item>

          {objectsChannelFields.map(item => (
            <FormChannelBinding
              key={item.name + '_field'}
              label={item.label}
              name={item.name}
              onClick={() => {
                setIsBindingOpen(true)
                setIsBindingActive(true)
                setActiveChannelField(item.name)
              }}
              onClear={() => {
                form.setFieldValue(item.name, '')
                form.setFieldValue(`${item.name}_name`, '')
              }}
            />
          ))}
        </Form>

        {isBindingActive && (
          <BindingChannels
            boundChannels={[]}
            isOpen={isBindingOpen}
            isMultiple={false}
            onSave={onSaveBindingHandler}
            onClose={() => {
              setIsBindingOpen(false)
              setTimeout(() => setIsBindingActive(false), 300)
            }}
          />
        )}
      </AddModal>
    </>
  )
}
