import React, { useContext, useEffect, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { MaskedInput } from 'antd-mask-input'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import type { NotificationsHandler, NotificationsSettingsType, NotificationsSubscriber } from 'widgets/plugins/notifications'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import type { MaskedInputChangeEvent } from 'shared/types'
import formValidate from 'shared/lib/form-validate'
import omitObject from 'shared/lib/omit-object'
import AddModal from 'shared/ui/modals/add-modal'

const { Text } = Typography

export const AddSubscriber: FC<{ handlerId: number }> = ({ handlerId }) => {
  const { state: { settings } } = useContext<ModuleContextType<NotificationsSettingsType>>(ModuleContext)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [formError, setFormError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)
  const { mutate } = useSWRConfig()

  useEffect(() => {
    emailNumberValidation()
  }, [values])

  const validationHandler = () => {
    if (emailNumberValidation())
      formValidate(form, onSave)
  }

  const emailNumberValidation = () => {
    const isTouched = form.isFieldsTouched(['subscriber_name', 'subscriber_email', 'subscriber_number'])

    if (isTouched && !form.getFieldValue('subscriber_email') && !phoneNumber) {
      setFormError('Введите email или номер телефона')
      return false
    } else {
      setFormError('')
      return true
    }
  }

  const onPhoneNumberChange = (e: MaskedInputChangeEvent) => {
    const valLength = e.unmaskedValue.length
    let errors: string[] = []

    if (valLength === 10)
      setPhoneNumber('+7' + e.unmaskedValue)
    else
      setPhoneNumber('')

    if (valLength === 0 || valLength === 10)
      errors = []
    else
      errors = ['Введите номер в правильном формате']

    form.setFields([{ name: 'subscriber_number', errors }])
  }

  const onSave = (values: NotificationsSubscriber) => {
    if (settings) {
      const set = omitObject(settings, ['uid'])

      set.handlers = set.handlers.map((handler: NotificationsHandler) => {
        if (handler.id === handlerId) {
          const subscribers = [
            ...handler.subscribers,
            {
              id: 0,
              subscriber_name: values.subscriber_name,
              subscriber_email: values.subscriber_email,
              subscriber_number: phoneNumber,
            }
          ].map((subscriber: NotificationsSubscriber, i: number) => ({ ...subscriber, id: i + 1 }))

          return { ...handler, subscribers }
        } else
          return handler
      })

      setLoading(true)

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        onClick={() => setIsModalOpen(true)}
      />

      <AddModal
        title="обработчика"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => validationHandler()}
        onCancel={() => {
          setIsModalOpen(false)
          form.resetFields()
          setFormError('')
          setPhoneNumber('')
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') validationHandler()
          }}
        >
          <Form.Item
            label="Имя"
            name="subscriber_name"
            rules={[{ required: true, message: 'Введите имя' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="subscriber_email"
            rules={[{ type: 'email', message: 'Неверный формат email адреса' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Номер"
            name="subscriber_number"
          >
            <MaskedInput
              mask={'+7 000 000 00 00'}
              onChange={onPhoneNumberChange}
            />
          </Form.Item>

          <Text type="danger">{formError}</Text>
        </Form>
      </AddModal>
    </>
  )
}
