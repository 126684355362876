import React, { useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { BindingChannels } from 'widgets/binding-channels'
import type { MFSSSettings } from 'widgets/plugins/mfss'
import { typeSelect } from 'widgets/plugins/mfss'
import type { ChannelsTableData } from 'entities/channel'
import type { DirTreeData } from 'entities/directory'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import filterNums from 'shared/lib/filter-nums'
import omitObject from 'shared/lib/omit-object'
import formValidate from 'shared/lib/form-validate'
import keyToId from 'shared/lib/key-to-id'
import generateInt64 from 'shared/lib/generate-int64'
import AddModal from 'shared/ui/modals/add-modal'
import { FormChannelBinding } from 'shared/ui/form/form-channel-binding'

const { Option } = Select

type MfssObjectFormType = {
  id: string
  label: string
  active: boolean
  type: number
  period: number
  core_channel_uid: string
  core_channel_uid_name: string
  critical_bottom_value: null | number
  warning_bottom_value: null | number
  warning_top_value: null | number
  critical_top_value: null | number
}

type AddMFSSObjectProps = {
  settings: MFSSSettings
  selectedFolder: DirTreeData | null
}

export const AddMFSSObject: FC<AddMFSSObjectProps> = ({ settings, selectedFolder }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isBindingOpen, setIsBindingOpen] = useState<boolean>(false)
  const [isBindingActive, setIsBindingActive] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  const onAdd = (values: MfssObjectFormType) => {
    if (settings && selectedFolder) {
      setLoading(true)

      const set = omitObject(settings, ['uid'])

      set.config.objects_list.push({
        ind: generateInt64(),
        object: {
          folder: +keyToId(selectedFolder.key),
          id: values.id,
          label: values.label,
          active: values.active,
          type: values.type,
          period: values.period,
          core_channel_uid: values.core_channel_uid ? values.core_channel_uid : '',
          critical_bottom_value: values.critical_bottom_value ? +values.critical_bottom_value : null,
          warning_bottom_value: values.warning_bottom_value ? +values.warning_bottom_value : null,
          warning_top_value: values.warning_top_value ? +values.warning_top_value : null,
          critical_top_value: values.critical_top_value ? +values.critical_top_value : null,
        }
      })

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  const onSaveBindingHandler = (data: Partial<ChannelsTableData>[]) => {
    if (data.length) {
      form.setFieldValue('core_channel_uid', keyToId(String(data[0].key)))
      form.setFieldValue('core_channel_uid_name', data[0].name)
    }
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        disabled={!selectedFolder || selectedFolder.key === 'dir-0'}
        onClick={() => {
          if (
            selectedFolder
            && selectedFolder.key
            && selectedFolder.key !== 'dir-0'
          ) setIsModalOpen(true)
        }}
      />

      <AddModal
        title="объекта"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={() => {
          setIsModalOpen(false)
          form.resetFields()
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
          initialValues={{
            type: 1,
            period: 1,
            active: true,
          }}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onAdd)
          }}
        >
          <Form.Item<MfssObjectFormType>
            label="ID"
            name="id"
            rules={[{ required: true, message: 'Введите ID' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<MfssObjectFormType>
            label="Название"
            name="label"
            rules={[{ required: true, message: 'Введите название' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<MfssObjectFormType>
            label="Тип"
            name="type"
          >
            <Select>
              {Object.entries(typeSelect).map(([key, val], i) => (
                <Option value={val} key={'type' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<MfssObjectFormType>
            label="Период"
            name="period"
          >
            <Input
              onKeyPress={filterNums}
              onBlur={(e) => {
                if (!e.target.value)
                  form.setFieldValue('period', 1)
              }}
            />
          </Form.Item>

          <Form.Item<MfssObjectFormType>
            label="Активный"
            name="active"
          >
            <Select>
              <Option value={true} key={'active1'}>{'true'}</Option>
              <Option value={false} key={'active2'}>{'false'}</Option>
            </Select>
          </Form.Item>

          <FormChannelBinding
            key={'core_channel_uid_field'}
            label={'Привязанный канал'}
            name={'core_channel_uid'}
            onClick={() => {
              setIsBindingOpen(true)
              setIsBindingActive(true)
            }}
            onClear={() => {
              form.setFieldValue('core_channel_uid', '')
              form.setFieldValue('core_channel_uid_name', '')
            }}
          />

          <Form.Item<MfssObjectFormType>
            label="Минимальное критическое значение"
            name="critical_bottom_value"
          >
            <Input onKeyPress={filterNums} />
          </Form.Item>

          <Form.Item<MfssObjectFormType>
            label="Максимальное критическое значение"
            name="critical_top_value"
          >
            <Input onKeyPress={filterNums} />
          </Form.Item>

          <Form.Item<MfssObjectFormType>
            label="Минимальное предупреждающее значение"
            name="warning_bottom_value"
          >
            <Input onKeyPress={filterNums} />
          </Form.Item>

          <Form.Item<MfssObjectFormType>
            label="Максимальное предупреждающее значение"
            name="warning_top_value"
          >
            <Input onKeyPress={filterNums} />
          </Form.Item>
        </Form>

        {isBindingActive && (
          <BindingChannels
            boundChannels={[]}
            isOpen={isBindingOpen}
            isMultiple={false}
            onSave={onSaveBindingHandler}
            onClose={() => {
              setIsBindingOpen(false)
              setTimeout(() => setIsBindingActive(false), 300)
            }}
          />
        )}
      </AddModal>
    </>
  )
}
