import React, { useContext, useEffect, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button } from 'antd'
import { ModuleContext } from 'layouts/module-layout'
import { setIsChanged } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import omitObject from 'shared/lib/omit-object'
import { subscribeEvent, unsubscribeEvent } from 'shared/lib/custom-events'

export const SetModuleSettings: FC<{ id: string | null }> = ({ id }) => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<any>>(ModuleContext)
  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false)
  const [isEnterPressed, setIsEnterPressed] = useState<boolean>(false)
  const { mutate } = useSWRConfig()

  useEffect(() => {
    subscribeEvent('module:onPressEnter', () => setIsEnterPressed(true))
    return () => unsubscribeEvent('module:onPressEnter', () => setIsEnterPressed(true))
  }, [])

  useEffect(() => {
    if (isEnterPressed) onSave()
  }, [isEnterPressed])

  const onSave = () => {
    if (id && settings) {
      setIsLoadingBtn(true)

      setModuleSettings(id, omitObject(settings, ['uid']))
        .then(() => {
          mutate(`${SETTINGS_URL}${id}`)
          reducerDispatch(setIsChanged(false))
          setIsEnterPressed(false)
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoadingBtn(false))
    }
  }

  return (
    <Button
      type="primary"
      size={'small'}
      loading={isLoadingBtn}
      disabled={!isChanged}
      onClick={onSave}
    >
      Применить настройки
    </Button>
  )
}
