import React, { useState } from 'react'
import type { FC } from 'react'
import { Button, Form, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import type { VarItem, VarNames } from 'shared/types'
import formValidate from 'shared/lib/form-validate'
import AddModal from 'shared/ui/modals/add-modal'

type AddVarProps = {
  variables: VarItem[]
  varNameField: VarNames
  onEdit: (vars: VarItem[]) => Promise<any>
}

export const AddVar: FC<AddVarProps> = ({ variables, varNameField, onEdit }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()

  const onAdd = (values: { name: string }) => {
    if (variables) {
      const newVars = variables

      setLoading(true)

      newVars.push({
        [varNameField]: values.name,
        channels: []
      })

      onEdit(newVars).then(() => {
        setLoading(false)
        setIsModalOpen(false)
        form.resetFields()
      })
    }
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        onClick={() => setIsModalOpen(true)}
      />

      <AddModal
        title="переменной"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={() => {
          setIsModalOpen(false)
          form.resetFields()
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onAdd)
          }}
        >
          <Form.Item
            label="Имя переменной"
            name="name"
            rules={[{ required: true, message: 'Введите имя переменной' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </AddModal>
    </>
  )
}
