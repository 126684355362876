import { ChannelsResponse } from 'entities/channel'
import { DirTreeData } from 'entities/directory'
import type { ModuleSettings } from 'entities/module'
import keyToId from 'shared/lib/key-to-id'
import createObjectsArray from 'shared/lib/objects/create-objects-array'
import type { MFSSObjectType, MFSSTableData } from '../types'

export const createTableData = (settings: ModuleSettings<MFSSObjectType>, channelsData: ChannelsResponse | undefined, selectedFolder: DirTreeData): MFSSTableData[] => {
  const objects = createObjectsArray(settings, +keyToId(selectedFolder.key))

  return settings.config.objects_list.reduce<MFSSTableData[]>((acc, curr) => {
    if (objects.includes(curr.ind)) {
      const channel = channelsData?.channels.find(ch => ch.uid === curr.object.core_channel_uid)

      acc.push({
        key: 'object-' + curr.ind,
        id: curr.object.id,
        label: curr.object.label,
        core_channel_uid: { name: channel ? channel.name : '', id: channel ? channel.uid : '' },
        type: curr.object.type,
        active: curr.object.active,
        period: curr.object.period,
        critical_bottom_value: curr.object.critical_bottom_value,
        warning_bottom_value: curr.object.warning_bottom_value,
        warning_top_value: curr.object.warning_top_value,
        critical_top_value: curr.object.critical_top_value,
      })
    }

    return acc
  }, [])
}
