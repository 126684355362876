import React, { useContext } from 'react'
import type { FC } from 'react'
import { Flex } from 'antd'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { ModuleCommands } from 'features/module/commands'
import { AddOPCObject } from 'features/opc/add-opc-object'
import createSearchChannelsArr from 'shared/lib/objects/create-search-channels-arr'
import getObjectsToSave from 'shared/lib/objects/get-objects-to-save'
import { ModuleObjects } from 'shared/ui/module-objects'
import { COLUMNS, commands } from '../config'
import type { OPCClientSettings } from '../types'
import { createTableData } from '../model/create-table-data'
import { OPCClientForm } from './opc-client-form'

export const OpcUaClient: FC = () => {
  const { state: { settings }, reducerDispatch } = useContext<ModuleContextType<OPCClientSettings>>(ModuleContext)

  return (
    <Flex vertical style={{ height: '100%' }}>
      <div className={'content-wrapper bd-bottom'}>
        <ModuleCommands
          id={settings.uid}
          commands={commands}
        />
      </div>

      <div className={'content-wrapper'}>
        <OPCClientForm />
      </div>

      <ModuleObjects
        settings={settings}
        reducerDispatch={reducerDispatch}
        columns={COLUMNS}
        objectsChannelFields={[{ label: 'Привязанный канал', name: 'core_channel_uid' }]}
        AddComponent={AddOPCObject}
        createChannelsArr={createSearchChannelsArr}
        createTableData={createTableData}
        getObjectsToSave={getObjectsToSave}
      />
    </Flex>
  )
}
