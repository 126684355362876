import type { CustomColumn } from 'shared/ui/table/table-responsive'
import { eventSelect } from 'entities/module'

export const separatorSelect = {
  'space': '%20',
  '\\t': '%09',
  '\\n': '%0A',
  ':': '%3A',
  ';': '%3B',
  ',': '%2C',
}

export const COLUMNS: CustomColumn[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 60,
  },
  {
    title: 'Событие',
    dataIndex: 'event',
    key: 'event',
    width: 200,
    editable: true,
    inputType: 'select' as const,
    selectData: eventSelect,
    render: (text: string) => Object.keys(eventSelect)[+text]
  },
  {
    title: 'Период',
    dataIndex: 'period',
    key: 'period',
    width: 80,
    editable: true,
    valueType: 'number' as const,
  },
  {
    title: 'Запрос',
    dataIndex: 'query',
    key: 'query',
    ellipsis: true,
    width: 200,
    render: (text: string) => decodeURIComponent(text),
  },
  {
    title: 'Канал ответа на запрос',
    dataIndex: 'channel-response',
    key: 'channel-response',
    width: 200,
    isChannel: true,
    render: (text: string | undefined) => text ?? 'Канал не найден',
  },
  {
    title: 'Разделитель колонок',
    dataIndex: 'col-separator',
    key: 'col-separator',
    editable: true,
    inputType: 'select' as const,
    selectData: separatorSelect,
    render: (text: string) => (
      Object.entries(separatorSelect).find((arr) => arr[1] === text)?.[0]
    )
  },
  {
    title: 'Разделитель строк',
    dataIndex: 'row-separator',
    key: 'row-separator',
    editable: true,
    inputType: 'select' as const,
    selectData: separatorSelect,
    render: (text: string) => (
      Object.entries(separatorSelect).find((arr) => arr[1] === text)?.[0]
    )
  },
].map(col => (
  !col.editable
    ? {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        isChannel: col.isChannel,
      }),
    }
    : {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        valueType: col.valueType,
        selectData: col.selectData,
      }),
    }
))
