import React, { useContext, useEffect } from 'react'
import type { FC } from 'react'
import { Col, Form, Input, Row } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { dispatchEvent } from 'shared/lib/custom-events'
import filterNums from 'shared/lib/filter-nums'
import type { DiwoFields, DiwoSettings } from '../types'

export const DiwoForm: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<DiwoSettings>>(ModuleContext)
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  useEffect(() => {
    if (isChanged) {
      form.validateFields({ validateOnly: true })
        .then(
          () => reducerDispatch(setIsChanged(true)),
          () => reducerDispatch(setIsChanged(false)),
        )
    }
  }, [values])

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        url_auth: settings.url_auth,
        url_data: settings.url_data,
        auth_login: settings.auth_login,
        auth_passwd: settings.auth_passwd,
        company_id: settings.company_id,
        max_objects: settings.max_objects,
        polling_objects_period: settings.polling_objects_period,
      })
    }
  }, [settings])

  return (
    <Form
      layout={'vertical'}
      form={form}
      className={'module'}
      onKeyUp={e => {
        if (e.code === 'Enter' && isChanged) dispatchEvent('module:onPressEnter')
      }}
    >
      <Row gutter={[10, 0]} align={'bottom'}>
        <Col span={6}>
          <Form.Item<DiwoFields>
            label={'Адрес сервера авторизации'}
            name={'url_auth'}
            rules={[{ required: true, message: 'Введите адрес сервера' }]}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  url_auth: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<DiwoFields>
            label={'Адрес сервера данных'}
            name={'url_data'}
            rules={[{ required: true, message: 'Введите адрес сервера' }]}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  url_data: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<DiwoFields>
            label={'Логин'}
            name={'auth_login'}
            rules={[{ required: true, message: 'Введите логин' }]}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  auth_login: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<DiwoFields>
            label={'Пароль'}
            name={'auth_passwd'}
            rules={[{ required: true, message: 'Введите пароль' }]}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  auth_passwd: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[10, 0]} align={'bottom'}>
        <Col span={6}>
          <Form.Item<DiwoFields>
            label={'Идентификатор компании'}
            name={'company_id'}
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  company_id: (+e.target.value) ? +e.target.value : 0
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<DiwoFields>
            label={'Период опроса'}
            name={'polling_objects_period'}
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  polling_objects_period: (+e.target.value) ? +e.target.value : 1
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<DiwoFields>
            label={'Максимальное количество объектов опроса'}
            name={'max_objects'}
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  max_objects: (+e.target.value) ? +e.target.value : 1
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
