import { configureStore } from '@reduxjs/toolkit'
import { tabsReducer } from 'entities/tab'
import { moduleReducer } from 'entities/module'
import { directoryReducer } from 'entities/directory'
import { logReducer } from 'entities/log'
import { projectReducer } from 'entities/project'
import { channelReducer } from 'entities/channel'

export const store = configureStore({
  reducer: {
    tabs: tabsReducer,
    log: logReducer,
    module: moduleReducer,
    directory: directoryReducer,
    project: projectReducer,
    channel: channelReducer,
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch
