import React from 'react'
import type { FC, Key } from 'react'
import { AddChannel } from 'features/channel/add-channel'
import { DeleteChannel } from 'features/channel/delete-channel'
import { ChangeChannel } from 'features/channel/change-channel'
import { EditChannels } from 'features/channel/edit-channels'
import type { DirTreeData } from 'entities/directory'
import type { ActionType, EditChannelType } from 'entities/channel'
import BlockHeader from 'shared/ui/block-header'

type HeaderProps = {
  onAction: (loading: boolean, type?: ActionType) => void,
  items: EditChannelType[]
  selectedRows: Key[],
  activeDirectory: DirTreeData | null
}

export const Header: FC<HeaderProps> = ({ items, activeDirectory, selectedRows, onAction }) => {
  return (
    <BlockHeader
      actionButtons={[
        <AddChannel
          key={'btn-add-channel'}
          selectedDirectory={activeDirectory}
          onAdded={() => {
            onAction(true)
          }}
        />,
        <DeleteChannel
          key={'btn-delete-channel'}
          onDeleted={() => {
            onAction(true)
          }}
        />,
        <ChangeChannel
          key={'btn-change-channel'}
          items={items}
          onChange={(loading, type) => onAction(loading, type)}
        />,
        <EditChannels
          key={'btn-edit-channels'}
          selectedRows={selectedRows}
        />
      ]}
    />
  )
}
