import React, { useContext, useLayoutEffect, useState } from 'react'
import type { FC, ChangeEvent, FocusEvent } from 'react'
import { Input } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import filterNums from 'shared/lib/filter-nums'
import { dispatchEvent } from 'shared/lib/custom-events'
import type { LuaSettingsType } from '../types'

export const InputTimeout: FC = () => {
  const { state: { settings }, reducerDispatch } = useContext<ModuleContextType<LuaSettingsType>>(ModuleContext)
  const [timeout, setTimeout] = useState<number>(1)

  useLayoutEffect(() => {
    if (settings) {
      setTimeout(settings.timeout)
    }
  }, [settings])

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    reducerDispatch(setSettings({
      ...settings,
      timeout: +e.target.value
    }))
    reducerDispatch(setIsChanged(true))
  }

  const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
    if (!e.target.value || +e.target.value < 1)
      setTimeout(1)
  }

  return (
    <div className={'input-item'}>
      <label htmlFor="timeout">Таймаут выполнения: &nbsp;</label>
      <Input
        id={'timeout'}
        value={timeout}
        onKeyPress={filterNums}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        onKeyUp={e => {
          if (e.code === 'Enter') dispatchEvent('module:onPressEnter')
        }}
      />
    </div>
  )
}
