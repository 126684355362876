import React, { useEffect, useRef, useState } from 'react'
import type { ReactElement, Dispatch, Key } from 'react'
import { useSWRConfig } from 'swr'
import type { BaseButtonProps } from 'antd/es/button/button'
import type { ExpandableConfig } from 'rc-table/lib/interface'
import { setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleReducerAction } from 'layouts/module-layout'
import { BindingChannels } from 'widgets/binding-channels'
import { DeleteRequest } from 'features/table/delete-request'
import type { ChannelItem, ChannelsTableData } from 'entities/channel'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import omitObject from 'shared/lib/omit-object'
import keyToId from 'shared/lib/key-to-id'
import { useChannels } from 'shared/hooks/use-channels'
import { TableResponsive } from 'shared/ui/table/table-responsive'
import type { CustomColumn } from 'shared/ui/table/table-responsive'
import { EditableRow } from 'shared/ui/table/editable-row'
import { CellWithButton } from 'shared/ui/cell-with-button'
import { EditableCell } from 'shared/ui/table/editable-cell'
import { updateTableData } from './model/update-table-data'

type RequestsTableGenericType = { [key: string]: any }

type RequestsTableType<T = any> = {
  settings: T
  reducerDispatch: Dispatch<ModuleReducerAction>
  itemsName: string
  itemPrefix: string
  deleteText: string
  isChanged: boolean
  columns: CustomColumn[]
  rowSelectionWidth?: number
  headerComponents?: ReactElement<BaseButtonProps>[]
  renderSelectionCell?: (value: boolean, record: any, index: number, originNode: React.ReactNode) => ReactElement,
  expandable?: ExpandableConfig<any>
}

/**
 * @param {string} itemsName - Имя поля массива элементов, отображаемых в таблице.
 * @param {CustomColumn[]} columns - Колонки таблицы должны иметь такой же индекс, как и у полей элементов.
 * */
export const RequestsTable = <T extends RequestsTableGenericType>({
  settings,
  reducerDispatch,
  itemsName,
  itemPrefix,
  deleteText = '',
  columns,
  rowSelectionWidth,
  headerComponents = [],
  renderSelectionCell,
  expandable,
}: RequestsTableType<T>) => {
  const [tableData, setTableData] = useState<any[]>([])
  const [channelsArr, setChannelsArr] = useState<string[] | null>(null)
  const [bindChannel, setBindChannel] = useState<ChannelItem[]>([])
  const [selectedId, setSelectedId] = useState<number | null>(null)
  const [selectedChannelType, setSelectedChannelType] = useState<string | null>(null)
  const [selectedRows, setSelectedRows] = useState<Key[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const tableRef = useRef(null)
  const { mutate } = useSWRConfig()
  const { data, isLoading } = useChannels(channelsArr)

  useEffect(() => {
    setTableData(() => (
      updateTableData(settings[itemsName], data?.channels, columns, itemPrefix)
    ))
  }, [data])

  useEffect(() => {
    if (settings) {
      setTableData(() => (
        updateTableData(settings[itemsName], data?.channels, columns, itemPrefix)
      ))

      setChannelsArr(() => {
        const channels: Set<string> = new Set()

        settings[itemsName].forEach((item: any) => {
          columns.forEach(col => {
            if (col.isChannel && col.key && item[col.key] && item[col.key] !== '0')
              channels.add(item[col.key])
          })
        })

        return channels.size ? Array.from(channels) : null
      })
    }
  }, [settings])

  const handleChange = (record: any, changedValue: any) => {
    reducerDispatch(setSettings({
      ...settings,
      [itemsName]: settings[itemsName].map((item: any) => (
        (item.id === record.id) ? { ...item, ...changedValue } : item
      ))
    }))
    reducerDispatch(setIsChanged(true))
  }

  const onSaveHandler = async (data: Partial<ChannelsTableData>[]) => {
    if (settings && selectedChannelType) {
      setLoading(true)
      const set = omitObject(settings, ['uid'])

      set[itemsName as keyof typeof set] = set[itemsName].map((item: any) => (
        (item.id === selectedId)
          ? {
            ...item,
            [selectedChannelType]: data.length ? keyToId(String(data[0].key)) : ''
          } : item
      ))

      return setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
        })
    }
  }

  return (
    <div
      ref={tableRef}
      className={'module-table'}
    >
      <TableResponsive
        columns={columns}
        dataSource={tableData}
        parentRef={tableRef}
        loading={isLoading}
        expandable={expandable}
        titleComponents={[
          ...headerComponents,
          <DeleteRequest<T>
            key={'DeleteRequest'}
            settings={settings}
            keys={selectedRows}
            itemsName={itemsName}
            itemPrefix={itemPrefix}
            modalText={deleteText}
            isDisabled={!selectedRows.length}
            onDeleteEvent={() => setSelectedRows([])}
          />
        ]}
        components={{
          body: {
            row: (props: any) => <EditableRow handleChange={handleChange} {...props} />,
            cell: (props: any) => {
              const { dataIndex, record, isChannel, ...restProps } = props

              if (isChannel)
                return (
                  <CellWithButton
                    {...restProps}
                    style={(props.children[1] === 'Канал не найден') ? { backgroundColor: '#ff7875' } : {}}
                    onClick={() => {
                      setBindChannel(() => {
                        if (record[dataIndex]) {
                          const currCh = data?.channels.find(ch => ch.name === record[dataIndex])

                          if (currCh)
                            return [{
                              key: currCh ? 'channel-' + currCh.uid : '',
                              name: record[dataIndex]
                            }]
                        }

                        return []
                      })
                      setSelectedId(record.id)
                      setSelectedChannelType(dataIndex)
                      setIsModalOpen(true)
                    }}
                  />
                )
              else
                return <EditableCell record={record} dataIndex={dataIndex} {...restProps} />
            },
          },
        }}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedRows,
          columnWidth: rowSelectionWidth,
          onChange: (selectedRowKeys: Key[]) => {
            setSelectedRows(selectedRowKeys)
          },
          getCheckboxProps: (record: ChannelsTableData) => ({
            name: String(record.key),
          }),
          renderCell: renderSelectionCell
        }}
      />

      {selectedId && (
        <BindingChannels
          boundChannels={bindChannel}
          isLoading={loading}
          isOpen={isModalOpen}
          isMultiple={false}
          onSave={onSaveHandler}
          onClose={() => {
            setIsModalOpen(false)
            setSelectedId(null)
            setBindChannel([])
            setSelectedChannelType(null)
          }}
        />
      )}
    </div>
  )
}
