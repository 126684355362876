import React, { useContext, useEffect, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import type { WialonSettings } from 'widgets/plugins/wialon/types'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import omitObject from 'shared/lib/omit-object'
import formValidate from 'shared/lib/form-validate'
import EditModal from 'shared/ui/modals/edit-modal'

export const EditFolder: FC<{ folderId: number | null, folderName: string }> = ({ folderId, folderName }) => {
  const { state: { settings } } = useContext<ModuleContextType<WialonSettings>>(ModuleContext)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  useEffect(() => {
    if (folderName)
      form.setFieldValue('directoryName', folderName)
  }, [folderName])

  const onSave = (values: any) => {
    if (settings && folderId !== null) {
      const set = omitObject(settings, ['uid'])

      set.config.folders_list = set.config.folders_list.map((f: any) => {
        if (f.ind === folderId)
          return { ...f, folder: { ...f.folder, label: values.directoryName } }
        else return f
      })

      setLoading(true)

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  return (
    <>
      <Button
        type="primary"
        icon={<EditOutlined />}
        size={'small'}
        onClick={() => (folderId !== null) && setIsModalOpen(true)}
      />

      <EditModal
        title="директории"
        open={isModalOpen}
        confirmLoading={loading}
        onSave={() => formValidate(form, onSave)}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onSave)
          }}
        >
          <Form.Item
            label="Имя директории"
            name="directoryName"
            rules={[{ required: true, message: 'Введите имя директории' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </EditModal>
    </>
  )
}
