import React, { useEffect, useRef, useState } from 'react'
import type { FC } from 'react'
import { Button, Divider, Flex, Form, Input, message, Space, Typography, Upload } from 'antd'
import type { FormInstance } from 'antd/lib'
import { getLicense, postLicense } from 'shared/api/license'
import fileSaver from 'shared/lib/file-saver'
import css from './License.module.scss'

const { TextArea } = Input
const { Text, Title } = Typography

export const License: FC = () => {
  const [isValid, setIsValid] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isApplyLoading, setIsApplyLoading] = useState<boolean>(false)
  const [licenseText, setLicenseText] = useState<string>('')
  const formRef = useRef<FormInstance<any> | null>(null)
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  useEffect(() => {
    form.validateFields({ validateOnly: true })
      .then(
        () => setIsValid(true),
        () => setIsValid(false),
      )
  }, [values])

  const getLicenseHandler = () => {
    form.validateFields()
      .then(() => {
        setIsLoading(true)
        getLicense(form.getFieldValue('serial'))
          .then((res) => {
            form.setFieldValue('reqText', decodeURIComponent(res))
          })
          .catch(err => console.error(err))
          .finally(() => setIsLoading(false))
      })
      .catch(() => console.warn('Validate failed!'))
  }

  const applyLicenseHandler = () => {
    setIsApplyLoading(true)

    postLicense(encodeURIComponent(licenseText))
      .then(() => {
        message.success({
          content: 'Файл лицензии отправлен.',
          duration: 5,
        })
      })
      .catch(err => console.error(err))
      .finally(() => setIsApplyLoading(false))
  }

  return (
    <div className={css.License}>
      <div className={css.License_content}>
        <div className={css.License_col}>
          <Title level={5}>Запрос на лицензирование</Title>

          <Form
            form={form}
            ref={formRef}
            layout={'vertical'}
          >
            <Form.Item
              label={'Серийный номер лицензии'}
              name="serial"
              rules={[
                { required: true, message: 'Введите номер лицензии' },
                { pattern: /^([\w-]+)$/g, message: 'Строка может содержать латинские буквы, цифры и знаки "-", "_"' },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={'Содержимое файла лицензии'}
              name={'reqText'}
            >
              <TextArea
                style={{ height: '300px', resize: 'none' }}
                className={'disabled'}
              />
            </Form.Item>

            <Flex gap="small" wrap="wrap">
              <Button
                type="primary"
                disabled={!isValid}
                loading={isLoading}
                onClick={getLicenseHandler}
              >
                Запросить
              </Button>

              <Button
                type="primary"
                disabled={formRef.current ? !form.getFieldValue('reqText') : false}
                onClick={() => {
                  fileSaver(
                    form.getFieldValue('reqText'),
                    form.getFieldValue('serial') + '_license'
                  )
                }}
              >
                Сохранить файл
              </Button>
            </Flex>
          </Form>
        </div>

        <Divider type="vertical" style={{ height: 'auto' }} />

        <div className={css.License_col}>
          <Title level={5}>Применить лицензию*</Title>

          <Form layout={'vertical'}>
            <Form.Item label={'Содержимое файла лицензии'}>
              <TextArea
                value={licenseText}
                style={{ height: '300px', resize: 'none' }}
                onChange={e => setLicenseText(e.target.value)}
              />
            </Form.Item>
          </Form>

          <Space direction={'vertical'}>
            <Text type="secondary">* Изменения условий лицензии вступят в силу после перезагрузки сервиса.</Text>

            <Flex gap="small" wrap="wrap">
              <Upload
                showUploadList={false}
                beforeUpload={() => false}
                onChange={({ file }: any) => {
                  const reader = new FileReader()

                  reader.readAsText(file)
                  reader.onload = () => {
                    setLicenseText(String(reader.result))
                  }
                }}
              >
                <Button type="primary">Открыть файл</Button>
              </Upload>

              <Button
                type="primary"
                disabled={!licenseText}
                loading={isApplyLoading}
                onClick={applyLicenseHandler}
              >
                Применить
              </Button>
            </Flex>
          </Space>
        </div>
      </div>
    </div>
  )
}
