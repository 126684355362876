import type { ModuleSettings } from 'entities/module'
import keyToId from 'shared/lib/key-to-id'

const setObjectsConfig = (settings: ModuleSettings, record: any, changedValue: any) => (
  {
    ...settings,
    config: {
      ...settings.config,
      objects_list: settings.config.objects_list.map((item: any) => (
        (item.ind === +keyToId(record.key))
          ? {
            ...item,
            object: {
              ...item.object,
              ...changedValue
            }
          }
          : item
      ))
    }
  }
)

export default setObjectsConfig
