import React, { FC, useContext, useRef, useState } from 'react'
import { mutate } from 'swr'
import cn from 'classnames'
import CodeMirror from '@uiw/react-codemirror'
import { langs } from '@uiw/codemirror-extensions-langs'
import { xcodeLight } from '@uiw/codemirror-theme-xcode'
import { ModuleContext } from 'layouts/module-layout'
import { setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import type { ChannelsTableData } from 'entities/channel'
import type { VarItem } from 'shared/types'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import omitObject from 'shared/lib/omit-object'
import keyToId from 'shared/lib/key-to-id'
import { VariablesTable } from 'shared/ui/table/variables-table'
import { ChannelsList } from 'shared/ui/channels-list'
import type { LuaSettingsType } from '../types'
import { TopForm } from './top-form'
import css from '../LuaScript.module.scss'

export const LuaScript: FC = () => {
  const { state: { settings }, reducerDispatch } = useContext<ModuleContextType<LuaSettingsType>>(ModuleContext)
  const [isVarsLoading, setIsVarsLoading] = useState<boolean>(false)
  const [isTriggersLoading, setIsTriggersLoading] = useState<boolean>(false)
  const varsRef = useRef(null)

  const onChangeCodeHandler = (val: string) => {
    reducerDispatch(setSettings({
      ...settings,
      script: encodeURIComponent(val)
    }))
    reducerDispatch(setIsChanged(true))
  }

  const onSaveVarsHandler = async (vars: VarItem[]) => {
    if (settings) {
      setIsVarsLoading(true)

      const set = omitObject(settings, ['uid'])

      set.variables = vars

      return setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => setIsVarsLoading(false))
    }
  }

  const onSaveTriggersHandler = async (data: Partial<ChannelsTableData>[]) => {
    setIsTriggersLoading(true)
    const newChannels = data.map(d => keyToId(String(d.key)))

    if (settings) {
      const set = omitObject(settings, ['uid'])

      set.triggers = newChannels

      return setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setIsTriggersLoading(false)
        })
    }
  }

  return (
    settings ? (
      <div className={css.LuaScript}>
        <div className={cn([css.LuaScript_top, 'bd-bottom'])}>
          <TopForm checked={settings.periodic} />
        </div>

        <div className={cn([css.LuaScript_content, 'module-table'])}>
          <div className={cn([css.LuaScript_contentItem, 'bd-right'])}>
            <CodeMirror
              value={decodeURIComponent(settings.script)}
              extensions={[langs.lua()]}
              theme={xcodeLight}
              onChange={onChangeCodeHandler}
            />
          </div>

          <div className={cn([css.LuaScript_contentItem, 'bd-left'])}>
            <div ref={varsRef} className={css.LuaScript_tableWrapper}>
              <div className={css.LuaScript_table}>
                <VariablesTable
                  parentRef={varsRef}
                  variables={settings.variables}
                  varNameField={'var'}
                  isLoading={isVarsLoading}
                  onSave={onSaveVarsHandler}
                />
              </div>
            </div>
          </div>
        </div>

        <ChannelsList
          channels={settings.triggers}
          title={'Триггеры'}
          isLoading={isTriggersLoading}
          onSave={onSaveTriggersHandler}
        />
      </div>
    ) : null
  )
}
