import type { CustomColumn } from 'shared/ui/table/table-responsive'
import type { ObjectsFieldsType } from 'shared/ui/module-objects'
import type { VisorlabsObject } from './types'

export const commands = [
  {
    label: 'Получить структуру',
    command: 'create_struct'
  },
  {
    label: 'Отобразить структуру',
    command: 'create_channels'
  },
]

export const objectsChannelFields: ObjectsFieldsType<VisorlabsObject>[] = [
  {
    label: 'Канал названия категории',
    name: 'primary_category_channel'
  },
  {
    label: 'Канал идентификатора',
    name: 'person_id_channel'
  },
  {
    label: 'Канал названия зоны',
    name: 'zone_channel'
  },
]

export const COLUMNS: CustomColumn[] = [
  {
    title: 'Название',
    dataIndex: 'label',
    key: 'label',
    width: 120,
    editable: true,
  },
  {
    title: 'Канал названия категории',
    dataIndex: 'primary_category',
    key: 'primary_category',
    ellipsis: true,
    isChannel: true,
    width: 180,
    render: (text: { name: string, id: string }) => text.name ?? 'Канал не найден'
  },
  {
    title: 'Канал идентификатора',
    dataIndex: 'person_id',
    key: 'person_id',
    ellipsis: true,
    isChannel: true,
    width: 180,
    render: (text: { name: string, id: string }) => text.name ?? 'Канал не найден'
  },
  {
    title: 'Канал названия зоны',
    dataIndex: 'zone',
    key: 'zone',
    ellipsis: true,
    isChannel: true,
    width: 180,
    render: (text: { name: string, id: string }) => text.name ?? 'Канал не найден'
  },
].map(col => (
  !col.editable
    ? {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        isChannel: col.isChannel,
        ellipsis: col.ellipsis,
      }),
    }
    : {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        ellipsis: col.ellipsis,
      }),
    }
))
