import React, { useContext, useEffect } from 'react'
import type { FC } from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { AddRequest } from 'features/snmp/add-request'
import { dispatchEvent } from 'shared/lib/custom-events'
import filterNums from 'shared/lib/filter-nums'
import { RequestsTable } from 'shared/ui/table/requests-table'
import type { SNMPSettingsType, SNMPFields } from './types'
import { COLUMNS, versionSelect } from './config'

const { Option } = Select

export const SNMP: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<SNMPSettingsType>>(ModuleContext)
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        remote_host: settings.remote_host,
        community: settings.community,
        SNMP_version: settings.SNMP_version ?? 1,
        polling_period: settings.polling_period ?? 1000,
      })
    }
  }, [settings])

  useEffect(() => {
    if (isChanged)
      form.validateFields()
        .then(() => reducerDispatch(setIsChanged(true)))
        .catch(() => reducerDispatch(setIsChanged(false)))
  }, [values])

  return (
    <>
      <div className={'content-wrapper'}>
        <Form
          name={'snmp-form'}
          form={form}
          layout={'vertical'}
          className={'module'}
          initialValues={{ SNMP_version: 1, polling_period: 1000 }}
          onKeyUp={e => {
            if (e.code === 'Enter') dispatchEvent('module:onPressEnter')
          }}
        >
          <Row gutter={[10, 0]} align={'bottom'}>
            <Col span={6}>
              <Form.Item<SNMPFields>
                label="Адрес устройства"
                name="remote_host"
                rules={[{ required: true, message: 'Введите адрес устройства' }]}
              >
                <Input
                  onChange={(e) => {
                    reducerDispatch(setSettings({
                      ...settings,
                      remote_host: e.target.value
                    }))
                    reducerDispatch(setIsChanged(true))
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item<SNMPFields>
                label="Пароль"
                name="community"
                rules={[{ required: true, message: 'Введите пароль' }]}
              >
                <Input
                  onChange={(e) => {
                    reducerDispatch(setSettings({
                      ...settings,
                      community: e.target.value
                    }))
                    reducerDispatch(setIsChanged(true))
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item<SNMPFields>
                label="Версия протокола SNMP"
                name="SNMP_version"
              >
                <Select
                  popupMatchSelectWidth={false}
                  onChange={(val) => {
                    reducerDispatch(setSettings({
                      ...settings,
                      SNMP_version: val
                    }))
                    reducerDispatch(setIsChanged(true))
                  }}
                >
                  {Object.entries(versionSelect).map(([key, val], i) => (
                    <Option value={val} key={'id' + i}>{key}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item<SNMPFields>
                label="Период опроса"
                name="polling_period"
              >
                <Input
                  onKeyPress={filterNums}
                  onChange={(e) => {
                    reducerDispatch(setSettings({
                      ...settings,
                      polling_period: +e.target.value
                    }))
                    reducerDispatch(setIsChanged(true))
                  }}
                  onBlur={(e) => {
                    if (!e.target.value)
                      form.setFieldValue('polling_period', 1)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <RequestsTable
        settings={settings}
        reducerDispatch={reducerDispatch}
        itemsName={'requests'}
        itemPrefix={'request-'}
        deleteText={'Удалить объект'}
        isChanged={isChanged}
        columns={COLUMNS}
        headerComponents={[<AddRequest key={'AddSNMPRequest'} />]}
      />
    </>
  )
}
