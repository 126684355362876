/*
* Строка должна быть в формате {name}-{id}
* */
const keyToId = (str: string) => {
  const prefix = str.split('-')[0] + '-'

  return str.slice(prefix.length)
}

export default keyToId
