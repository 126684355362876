import type { CustomColumn } from 'shared/ui/table/table-responsive'
import type { VarItem, VarNames } from 'shared/types'

const initColumn: CustomColumn = {
  title: 'Переменная',
  dataIndex: '#var#',
  key: '#var#',
  width: 100
}

/*
* Генерирует колонки таблицы
* и строки запросов каналов переменных
* */
export const generateColumns = (vars: VarItem[], varNameField: VarNames) => {
  let channelsUid: string[] = []

  let cols = vars.reduce((acc: CustomColumn[], curr: VarItem) => {
    if (curr.channels.length)
      channelsUid = [...channelsUid, ...curr.channels]

    acc.push({
      title: curr[varNameField],
      dataIndex: curr[varNameField] || '',
      key: curr[varNameField],
      width: 200,
    })

    return acc
  }, [initColumn])

  cols = cols.map((col: CustomColumn) => ({
    ...col,
    onHeaderCell: () => ({
      ...col,
      dataIndex: col.dataIndex
    })
  }))

  return { channelsUid, cols }
}
