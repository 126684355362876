import React, { useContext } from 'react'
import type { FC } from 'react'
import { Flex } from 'antd'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { ModuleCommands } from 'features/module/commands'
import { AddPosObject } from 'features/objects/add-pos-object'
import getPosObjectsToSave from 'shared/lib/objects/get-pos-objects-to-save'
import createPosSearchChannelsArr from 'shared/lib/objects/create-pos-search-channels-arr'
import { ModuleObjects, commands } from 'shared/ui/module-objects'
import { COLUMNS, objectsChannelFields } from '../config'
import type { DiwoSettings } from '../types'
import { createDiwoTableData } from '../model/create-diwo-table-data'
import { DiwoForm } from './diwo-form'

export const Diwo: FC = () => {
  const { state: { settings }, reducerDispatch } = useContext<ModuleContextType<DiwoSettings>>(ModuleContext)

  return (
    <Flex vertical style={{ height: '100%' }}>
      <div className={'content-wrapper bd-bottom'}>
        <ModuleCommands
          id={settings.uid}
          commands={commands}
        />
      </div>

      <div className={'content-wrapper'}>
        <DiwoForm />
      </div>

      <ModuleObjects
        settings={settings}
        reducerDispatch={reducerDispatch}
        columns={COLUMNS}
        objectsChannelFields={objectsChannelFields}
        AddComponent={AddPosObject}
        createChannelsArr={createPosSearchChannelsArr}
        createTableData={createDiwoTableData}
        getObjectsToSave={getPosObjectsToSave}
      />
    </Flex>
  )
}
