import type { ModuleSettings } from 'entities/module'
import createObjectsArray from 'shared/lib/objects/create-objects-array'
import type { ObjectType } from 'shared/ui/module-objects'

const createPosSearchChannelsArr = (settings: ModuleSettings<ObjectType>, dirId: number): string[] => {
  const objects = createObjectsArray(settings, dirId)

  const channelsSet: Set<string> = settings.config.objects_list.reduce((acc, curr) => {
    if (objects.includes(curr.ind)) {
      Object.keys(curr.object).map(item => {
        const channelId: string = `${curr.object[item as keyof ObjectType]}`

        if (item !== 'folder' && item !== 'label' && channelId)
          acc.add(channelId)
      })
    }

    return acc
  }, new Set<string>())

  return Array.from(channelsSet)
}

export default createPosSearchChannelsArr
