import React, { useContext, useEffect } from 'react'
import type { FC } from 'react'
import { Checkbox, Col, Form, Input, Row, Space } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { dispatchEvent } from 'shared/lib/custom-events'
import filterNums from 'shared/lib/filter-nums'
import type { OPCClientSettings, OPCClientFields } from '../types'

export const OPCClientForm: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<OPCClientSettings>>(ModuleContext)
  const [form] = Form.useForm()

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        url: settings.url,
        security: settings.security,
        login: settings.login,
        password: settings.password,
        polling_period: settings.polling_period,
        root_node_id: settings.root_node_id,
      })
    }
  }, [settings])

  const validateForm = (security: boolean) => {
    const fieldsToValidate = security
      ? ['url', 'login', 'password']
      : ['url']

    form.validateFields(fieldsToValidate)
      .then(() => reducerDispatch(setIsChanged(true)))
      .catch(() => reducerDispatch(setIsChanged(false)))
  }

  return (
    <Form
      layout={'vertical'}
      form={form}
      className={'module'}
      onKeyUp={e => {
        if (e.code === 'Enter' && isChanged) dispatchEvent('module:onPressEnter')
      }}
    >
      <Row gutter={[10, 0]} align={'bottom'}>
        <Col span={6}>
          <Form.Item<OPCClientFields>
            label={'Адрес сервера'}
            name={'url'}
            rules={[{ required: true, message: 'Введите адрес сервера' }]}
          >
            <Input
              onChange={(e) => {
                validateForm(settings.security)
                reducerDispatch(setSettings({
                  ...settings,
                  url: e.target.value
                }))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<OPCClientFields>
            label={'Период опроса'}
            name={'polling_period'}
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => {
                validateForm(e.target.checked)
                reducerDispatch(setSettings({
                  ...settings,
                  polling_period: (+e.target.value) ? +e.target.value : 1
                }))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<OPCClientFields>
            label={'Корневой узел'}
            name={'root_node_id'}
          >
            <Input
              onChange={(e) => {
                validateForm(e.target.checked)
                reducerDispatch(setSettings({
                  ...settings,
                  root_node_id: e.target.value
                }))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<OPCClientFields>
            name={'security'}
            valuePropName="checked"
          >
            <Space>
              Использовать логин/пароль:
              <Checkbox
                checked={settings.security}
                onChange={(e) => {
                  validateForm(e.target.checked)
                  reducerDispatch(setSettings({
                    ...settings,
                    security: e.target.checked
                  }))
                }}
              />
            </Space>
          </Form.Item>
        </Col>
      </Row>

      <Row
        gutter={[10, 0]}
        align={'bottom'}
        style={{ display: settings.security ? 'flex' : 'none' }}
      >
        <Col span={6}>
          <Form.Item<OPCClientFields>
            label={'Логин'}
            name={'login'}
            rules={[{ required: true, message: 'Введите логин' }]}
          >
            <Input
              onChange={(e) => {
                if (settings.security) {
                  validateForm(settings.security)
                  reducerDispatch(setSettings({
                    ...settings,
                    login: e.target.value
                  }))
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<OPCClientFields>
            label={'Пароль'}
            name={'password'}
            rules={[{ required: true, message: 'Введите пароль' }]}
          >
            <Input
              onChange={(e) => {
                if (settings.security) {
                  validateForm(settings.security)
                  reducerDispatch(setSettings({
                    ...settings,
                    password: e.target.value
                  }))
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
