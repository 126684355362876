import React from 'react'
import type { FC } from 'react'
import { Modal } from 'antd'
import type { ModalProps } from 'shared/types'

type DeleteModalProps = ModalProps & {
  text: string
  textValue?: string
  onDelete: () => void
}

const DeleteModal: FC<DeleteModalProps> = ({
  title = '',
  open,
  confirmLoading,
  text,
  textValue = '',
  onDelete,
  onCancel
}) => {
  return (
    <Modal
      title={title}
      open={open}
      onOk={onDelete}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      cancelText={'Отмена'}
      okText={'Удалить'}
      okButtonProps={{ danger: true }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: `${text}${textValue ? ` <b>${textValue}</b>` : ''}?`
        }}
      />
    </Modal>
  )
}

export default DeleteModal
