import type { ChangeModuleFn, Module, ModulesType, Plugin, CreateModuleFn } from 'entities/module'
import { API_URL } from 'shared/config'
import httpHandler from 'shared/lib/api/http-handler'

export const SETTINGS_URL = `${API_URL}/modules/settings/?uid=`

export async function getTypes(): Promise<ModulesType[]> {
  return await (await httpHandler(`${API_URL}/plugins/types`)).json()
}

export async function getPlugins(): Promise<Plugin[]> {
  return await (await httpHandler(`${API_URL}/plugins`)).json()
}

export async function getModules(): Promise<Module[]> {
  const data = await (await httpHandler(`${API_URL}/modules`)).json()
  return data.modules
}

export async function getModuleSettings(id: string | null): Promise<any> {
  if (id) {
    const data = await (await httpHandler(`${API_URL}/modules/settings/?uid=${id}`)).json()
    return data.modules[0]
  }
}

/**
 * If {id} is null the method executes for all modules.
 * */
export async function controlModule(id: string | null, action: 'start' | 'stop'): Promise<any> {
  if (id)
    return await httpHandler(`${API_URL}/modules/${action}/?uid=${id}`)
  else
    return await httpHandler(`${API_URL}/modules/${action}/all`)
}

export async function resetModule(id: string | null): Promise<any> {
  if (id)
    return await httpHandler(`${API_URL}/modules/reset/?uid=${id}`)
}

export async function executeModule(id: string, command: string) {
  if (id)
    return await httpHandler(`${API_URL}/modules/execute/?uid=${id}&cmd=${command}`)
}

export async function deleteModules(modules: string[]) {
  if (modules.length) {
    return await httpHandler(`${API_URL}/modules`, {
      method: 'DELETE',
      body: JSON.stringify({ modules }),
    })
  }
}

export const createModule: CreateModuleFn = async (name: string, plugin: string, group: string = '', description: string = '') => {
  const body = JSON.stringify({
    modules: [{
      name,
      plugin_name: plugin,
      group,
      description,
    }]
  })

  return await httpHandler(`${API_URL}/modules`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
}

export const changeModule: ChangeModuleFn = async (id, name, group, description = '') => {
  const body = JSON.stringify({
    modules: [{
      uid: id,
      name,
      group,
      description,
    }]
  })

  return await httpHandler(`${API_URL}/modules/parameters`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
}

export const setModuleSettings = async (id: string | null, settings: any) => {
  const body = JSON.stringify({
    modules: [{
      uid: id,
      settings
    }]
  })

  return await httpHandler(`${API_URL}/modules/settings`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
}
