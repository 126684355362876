import React, { FC, useState } from 'react'
import { useSWRConfig } from 'swr'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Select, Space } from 'antd'
import type { DirTreeData } from 'entities/directory'
import { qualitySelect, typesSelect } from 'entities/channel'
import type { CreateChannelType } from 'entities/channel'
import { DIR_URL } from 'shared/api/directories'
import { createChannel } from 'shared/api/channels'
import keyToId from 'shared/lib/key-to-id'
import AddModal from 'shared/ui/modals/add-modal'
import filterNums from 'shared/lib/filter-nums'
import formValidate from 'shared/lib/form-validate'

const { Option } = Select

type AddChannelType = {
  onAdded: () => void
  selectedDirectory: DirTreeData | null
}

export const AddChannel: FC<AddChannelType> = ({ onAdded, selectedDirectory }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [isMultiple, setIsMultiple] = useState<boolean>(false)
  const [channelsCount, setChannelsCount] = useState<string>('2')
  const [channelsStart, setChannelsStart] = useState<string>('1')
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  const onAdd = (values: CreateChannelType) => {
    setLoading(true)

    if (selectedDirectory && selectedDirectory.key) {
      const channels = []
      const channelData = {
        type: values.type,
        value: values.value || '',
        quality: values.quality,
        group: values.group || '',
        description: values.description || '',
        dir: keyToId(selectedDirectory.key)
      }

      if (isMultiple) {
        for (let i = 0; i < +channelsCount; i++) {
          channels.push({
            ...channelData,
            name: `${values.name}_${i + +channelsStart}`,
          })
        }
      } else {
        channels.push({
          ...channelData,
          name: values.name,
        })
      }

      createChannel(channels)
        .then(() => {
          clearState()
          onAdded()
          mutate(DIR_URL)
          form.resetFields()
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
    }
  }

  const clearState = () => {
    setIsModalOpen(false)
    setIsMultiple(false)
    setChannelsCount('2')
    setChannelsStart('1')
  }

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        size={'small'}
        onClick={() => {
          if (selectedDirectory && (selectedDirectory.key !== '') && (selectedDirectory.key !== 'dir-0'))
            setIsModalOpen(true)
        }}
      />

      <AddModal
        title="канала"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={clearState}
      >
        <Form
          form={form}
          layout={'vertical'}
          initialValues={{ type: 0, quality: 0 }}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onAdd)
          }}
        >
          <Form.Item<CreateChannelType>
            label="Имя канала"
            name="name"
            rules={[{ required: true, message: 'Введите имя канала' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<CreateChannelType>
            label="Тип канала"
            name="type"
          >
            <Select>
              {Object.entries(typesSelect).map(([key, val], i) => (
                <Option value={val} key={'type' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<CreateChannelType>
            label="Группы"
            name="group"
          >
            <Input />
          </Form.Item>

          <Form.Item<CreateChannelType>
            label="Значение"
            name="value"
          >
            <Input />
          </Form.Item>

          <Form.Item<CreateChannelType>
            label="Качество"
            name="quality"
          >
            <Select>
              {Object.entries(qualitySelect).map(([key, val], i) => (
                <Option value={val} key={'type' + i}>{key}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<CreateChannelType>
            label="Описание"
            name="description"
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <>
              <div>
                Добавить несколько:&nbsp;
                <Checkbox
                  checked={isMultiple}
                  onChange={e => setIsMultiple(e.target.checked)}
                />
              </div>

              {isMultiple && (
                <Space>
                  <Form.Item label={'Начать с:'}>
                    <Input
                      value={channelsStart}
                      onKeyPress={filterNums}
                      onChange={e => setChannelsStart(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item label={'Количество:'}>
                    <Input
                      value={channelsCount}
                      onKeyPress={filterNums}
                      onChange={e => setChannelsCount(e.target.value)}
                    />
                  </Form.Item>
                </Space>
              )
              }
            </>
          </Form.Item>
        </Form>
      </AddModal>
    </>
  )
}
