import type { Key } from 'react'
import type { CreateChannelType, EditChannelType, EditStateType, ChannelsResponse } from 'entities/channel'
import { API_URL } from 'shared/config'
import httpHandler from 'shared/lib/api/http-handler'

export async function getChannels(url: string): Promise<ChannelsResponse> {
  return await (await httpHandler(url)).json()
}

export async function deleteChannels(channels: Key[]) {
  if (channels.length) {
    return await httpHandler(`${API_URL}/channels`, {
      method: 'DELETE',
      body: JSON.stringify({ channels }),
    })
  }
}

export async function createChannelsSearch(channels: string[] | null): Promise<{ search: string } | undefined> {
  if (channels && channels.length) {
    const res = await httpHandler(`${API_URL}/channels/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ channels }),
    })

    return await res.json()
  }
}

export async function createChannel(channels: CreateChannelType[]) {
  return await httpHandler(`${API_URL}/channels`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ channels }),
  })
}

export async function changeChannels(items: EditChannelType[]) {
  const body = JSON.stringify({
    channels: items
  })

  return await httpHandler(`${API_URL}/channels/parameters`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
}

export async function changeChannelsState(items: EditStateType[]) {
  const body = JSON.stringify({
    channels: items
  })

  return await httpHandler(`${API_URL}/channels/state`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
}
