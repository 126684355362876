import React, { useContext } from 'react'
import type { FC } from 'react'
import { Flex } from 'antd'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { ModuleCommands } from 'features/module/commands'
import { AddPosObject } from 'features/objects/add-pos-object'
import createPosSearchChannelsArr from 'shared/lib/objects/create-pos-search-channels-arr'
import createPosTableData from 'shared/lib/objects/create-pos-table-data'
import getPosObjectsToSave from 'shared/lib/objects/get-pos-objects-to-save'
import { ModuleObjects, commands } from 'shared/ui/module-objects'
import { COLUMNS, objectsChannelFields } from '../config'
import type { WialonSettings } from '../types'
import { WialonForm } from './wialon-form'

export const Wialon: FC = () => {
  const { state: { settings }, reducerDispatch } = useContext<ModuleContextType<WialonSettings>>(ModuleContext)

  return (
    <Flex vertical style={{ height: '100%' }}>
      <div className={'content-wrapper bd-bottom'}>
        <ModuleCommands
          id={settings.uid}
          commands={commands}
        />
      </div>

      <div className={'content-wrapper'}>
        <WialonForm />
      </div>

      <ModuleObjects
        settings={settings}
        reducerDispatch={reducerDispatch}
        columns={COLUMNS}
        objectsChannelFields={objectsChannelFields}
        AddComponent={AddPosObject}
        createChannelsArr={createPosSearchChannelsArr}
        createTableData={createPosTableData}
        getObjectsToSave={getPosObjectsToSave}
      />
    </Flex>
  )
}
