export const interactionSelect = {
  'Только чтение': 1,
  'Только запись': 2,
  'Чтение и запись': 3,
}

export const COLUMNS = [
  {
    title: 'Имя топика',
    dataIndex: 'topic',
    key: 'topic',
    editable: true,
  },
  {
    title: 'Тип взаимодействия',
    dataIndex: 'interaction_type',
    key: 'interaction_type',
    editable: true,
    inputType: 'select' as const,
    selectData: interactionSelect,
    render: (text: string) => (
      Object.entries(interactionSelect).find((arr) => arr[1] === +text)?.[0]
    )
  },
  {
    title: 'Канал',
    dataIndex: 'result_channel',
    key: 'result_channel',
    isChannel: true,
    render: (text: string | undefined) => text ?? 'Канал не найден',
  },
].map(col => (
  !col.editable
    ? {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        isChannel: col.isChannel,
      }),
    }
    : {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        selectData: col.selectData,
      }),
    }
))
