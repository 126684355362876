import React, { useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Form, Input } from 'antd'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import formValidate from 'shared/lib/form-validate'
import AddModal from 'shared/ui/modals/add-modal'

type AddEndpointFormProps = {
  isOpen: boolean
  uid: string
  onAdd: (values: { path: string, type: string }) => any
  onCancel: () => void
}

export const AddEndpointModal: FC<AddEndpointFormProps> = ({ isOpen, uid, onAdd, onCancel }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const { mutate } = useSWRConfig()

  const onSave = (values: { path: string, type: string }) => {
    setLoading(true)

    const newSettings = onAdd(values)

    setModuleSettings(uid, newSettings)
      .then(() => mutate(`${SETTINGS_URL}${uid}`))
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false)
        form.resetFields()
        onCancel()
      })
  }

  return (
    <AddModal
      title="адреса"
      open={isOpen}
      confirmLoading={loading}
      onAdd={() => formValidate(form, onSave)}
      onCancel={() => onCancel()}
    >
      <Form
        form={form}
        layout={'vertical'}
        initialValues={{ type: 'application/json' }}
        onKeyUp={e => {
          if (e.code === 'Enter') formValidate(form, onSave)
        }}
      >
        <Form.Item
          label="Путь"
          name="path"
          rules={[{ required: true, message: 'Введите путь' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Тип контента"
          name="type"
        >
          <Input />
        </Form.Item>
      </Form>
    </AddModal>
  )
}
