import React, { useEffect, useRef, useState } from 'react'
import type { FC } from 'react'
import { mutate } from 'swr'
import cn from 'classnames'
import { Button, Modal, Space } from 'antd'
import { ExportOutlined } from '@ant-design/icons'
import CodeMirror from '@uiw/react-codemirror'
import { langs } from '@uiw/codemirror-extensions-langs'
import { xcodeLight } from '@uiw/codemirror-theme-xcode'
import type { DatabaseRequest, DatabaseSettingsType } from 'widgets/plugins/database'
import type { VarItem } from 'shared/types'
import { VariablesTable } from 'shared/ui/table/variables-table'
import omitObject from 'shared/lib/omit-object'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'

type EditRequestProps = {
  settings: DatabaseSettingsType
  requestId: number
}

export const EditRequest: FC<EditRequestProps> = ({ settings, requestId }) => {
  const [requestData, setRequestData] = useState<DatabaseRequest>()
  const [query, setQuery] = useState('')
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [isVarsLoading, setIsVarsLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const modalRef = useRef(null)

  useEffect(() => {
    if (requestId)
      setRequestData(() => (
        settings.requests.find(req => req.id === requestId)
      ))
  }, [requestId, settings])

  useEffect(() => {
    if (requestData)
      setQuery(decodeURIComponent(requestData.query))
  }, [requestData])

  const onSaveVarsHandler = async (vars: VarItem[]) => {
    if (settings) {
      setRequestData((prev) => {
        if (prev) {
          return { ...prev, aliases: vars }
        }
      })
    }
  }

  const onSaveModalHandler = () => {
    if (settings) {
      setConfirmLoading(true)

      const set = omitObject(settings, ['uid'])

      set.requests = settings.requests.map(req => (
        (req.id === requestId)
          ? {
            ...req,
            query: encodeURIComponent(query),
            aliases: requestData ? requestData.aliases : []
          } : req
      ))

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setIsVarsLoading(false)
          setConfirmLoading(false)
          setIsModalOpen(false)
        })
    }
  }
  return (
    <>
      <div style={{ display: 'flex' }}>
        <Space>
          <Button
            type="primary"
            icon={<ExportOutlined />}
            size={'small'}
            onClick={() => setIsModalOpen(true)}
          />
        </Space>
      </div>

      <Modal
        title={'Редактирование запроса'}
        open={isModalOpen}
        onOk={onSaveModalHandler}
        width={1200}
        onCancel={() => setIsModalOpen(false)}
        confirmLoading={confirmLoading}
        cancelText={'Отмена'}
        okText={'Сохранить'}
        forceRender
      >
        <div
          className={'modal-with-code'}
          ref={modalRef}
        >
          <div className={cn(['modal-with-code__item', 'bd-bottom', 'bd-left', 'bd-top'])}>
            <CodeMirror
              value={query}
              extensions={[langs.pgsql()]}
              theme={xcodeLight}
              onChange={(val: string) => setQuery(val)}
            />
          </div>

          <div
            className={cn([
              'modal-with-code__item',
              'modal-with-code__table',
              'bd-left',
              'bd-top',
            ])}
          >
            <VariablesTable
              parentRef={modalRef}
              variables={requestData ? Array.from(requestData.aliases) : []}
              varNameField={'alias'}
              isLoading={isVarsLoading}
              onSave={onSaveVarsHandler}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
