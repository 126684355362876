import React, { useCallback, useContext, useEffect, useState } from 'react'
import type { FC } from 'react'
import { Checkbox, Col, Form, Input, Row, Space, Typography } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { dispatchEvent } from 'shared/lib/custom-events'
import filterNums from 'shared/lib/filter-nums'
import debounce from 'shared/lib/debounce'
import type { WialonFields, WialonSettings } from '../types'

const { TextArea } = Input
const { Text } = Typography

export const WialonForm: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<WialonSettings>>(ModuleContext)
  const [isGeozoneInputFocus, setIsGeozoneInputFocus] = useState<boolean>(false)
  const [form] = Form.useForm()
  const values: { [key: string]: string } = Form.useWatch([], form)

  useEffect(() => {
    if (!isGeozoneInputFocus)
      changeField(values)
  }, [values])

  const changeField = useCallback(debounce((values: { [key: string]: string }) => {
    reducerDispatch(setSettings({
      ...settings,
      url: values.url,
      token: values.token,
      polling_objects_period: +values.polling_objects_period,
      max_objects: +values.max_objects,
      read_senses: values.read_senses,
    }))

    if (form.isFieldsTouched())
      reducerDispatch(setIsChanged(true))
  }), [settings])

  useEffect(() => {
    if (settings) {
      form.setFields([
        {
          name: 'url',
          value: settings.url,
          touched: false
        },
        {
          name: 'token',
          value: settings.token,
          touched: false
        },
        {
          name: 'polling_objects_period',
          value: settings.polling_objects_period,
          touched: false
        },
        {
          name: 'max_objects',
          value: settings.max_objects,
          touched: false
        },
        {
          name: 'read_senses',
          value: settings.read_senses,
          touched: false
        },
      ])

      if (settings.wialon_geozones && settings.wialon_geozones.length && !isGeozoneInputFocus)
        form.setFieldValue('wialon_geozones', settings.wialon_geozones.join('; ') + ';')

      if (isChanged) {
        form.validateFields({ validateOnly: true })
          .then(
            () => reducerDispatch(setIsChanged(true)),
            () => reducerDispatch(setIsChanged(false)),
          )
      }
    }
  }, [settings])

  const setGeozoneValue = (value: string) => {
    reducerDispatch(setSettings({
      ...settings,
      wialon_geozones: value.trim().split(';').reduce<string[]>((acc, curr) => {
        if (curr)
          acc.push(curr.trim())

        return acc
      }, [])
    }))
  }

  const setSensesValue = (value: boolean) => {
    reducerDispatch(setSettings({
      ...settings,
      read_senses: value
    }))
    reducerDispatch(setIsChanged(true))
  }

  return (
    <Form
      layout={'vertical'}
      form={form}
      className={'module'}
      onKeyUp={e => {
        if (e.code === 'Enter' && isChanged && !isGeozoneInputFocus)
          dispatchEvent('module:onPressEnter')
      }}
    >
      <Row gutter={[10, 0]} align={'bottom'}>
        <Col span={6}>
          <Form.Item<WialonFields>
            label={'Адрес сервера'}
            name={'url'}
            rules={[{ required: true, message: 'Введите адрес сервера' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<WialonFields>
            label={'Токен'}
            name={'token'}
            rules={[{ required: true, message: 'Введите токен' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<WialonFields>
            label={'Период опроса'}
            name={'polling_objects_period'}
          >
            <Input onKeyPress={filterNums} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<WialonFields>
            label={'Количество опрашиваемых объектов'}
            name={'max_objects'}
          >
            <Input onKeyPress={filterNums} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[10, 0]} align={'bottom'}>
        <Col span={12}>
          <Form.Item<WialonFields>
            name={'wialon_geozones'}
            label={
              <>
                Геозоны&nbsp;
                <Text type="secondary">(одно или несколько значений, разделенных &quot;<b>;</b>&quot;)</Text>
              </>
            }
          >
            <TextArea
              style={{ resize: 'none' }}
              onKeyDown={() => {
                if (!form.isFieldTouched('wialon_geozones') && !isChanged)
                  reducerDispatch(setIsChanged(true))
              }}
              onFocus={() => setIsGeozoneInputFocus(true)}
              onBlur={(e) => {
                setGeozoneValue(e.target.value)
                setIsGeozoneInputFocus(false)
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<WialonFields>
            name="read_senses"
            valuePropName="checked"
          >
            <Space>
              Считывать параметры датчиков
              <Checkbox
                checked={settings.read_senses}
                onChange={(e) => setSensesValue(e.target.checked)}
              />
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
