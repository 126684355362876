import type { FormInstance } from 'antd/es/form'

const formValidate = (form: FormInstance<any>, action: (values: any) => void) => {
  form
    .validateFields()
    .then((values) => action(values))
    .catch(info => console.warn('Form validation failed', info))
}

export default formValidate
