import { API_URL } from 'shared/config'
import type { ProjectState } from 'entities/project'
import httpHandler from 'shared/lib/api/http-handler'

export async function getProjects(): Promise<ProjectState> {
  const data = await (await httpHandler(`${API_URL}/projects`)).json()
  return data.projects
}

export async function loadProject(name: string): Promise<Response> {
  return httpHandler(`${API_URL}/projects/load?name=${name}`)
}

export async function saveProject(name: string): Promise<Response> {
  return httpHandler(`${API_URL}/projects/save?name=${name}`)
}

export async function newProject(name: string): Promise<Response> {
  return httpHandler(`${API_URL}/projects/new?name=${name}`)
}

export async function exportProject(name: string): Promise<{ hash: string, data: string}> {
  return await (await httpHandler(`${API_URL}/projects/export?name=${name}`)).json()
}

export async function importProject(data: { name: string, hash: string, data: string }) {
  return await httpHandler(`${API_URL}/projects/import`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}
