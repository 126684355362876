import React, { useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { BindingChannels } from 'widgets/binding-channels'
import { WialonRetranslatorObject, WRObjectChannels } from 'widgets/plugins/wialon-retranslator'
import type { AddWRFields, WialonRetranslatorSettings } from 'widgets/plugins/wialon-retranslator'
import type { ChannelsTableData } from 'entities/channel'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import omitObject from 'shared/lib/omit-object'
import keyToId from 'shared/lib/key-to-id'
import formValidate from 'shared/lib/form-validate'
import AddModal from 'shared/ui/modals/add-modal'
import { FormChannelBinding } from 'shared/ui/form/form-channel-binding'

type AddWRObjectPropsType = {
  settings: WialonRetranslatorSettings
}

const AddWRObject: FC<AddWRObjectPropsType> = ({ settings }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isBindingOpen, setIsBindingOpen] = useState<boolean>(false)
  const [isBindingActive, setIsBindingActive] = useState<boolean>(false)
  const [activeChannelField, setActiveChannelField] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  const onAdd = (values: AddWRFields) => {
    if (settings) {
      setLoading(true)

      const set = omitObject(settings, ['uid'])

      set.objects_list.push({
        id: 0,
        wid: values.wid || '',
        lat_channel: values.lat_channel || '',
        lon_channel: values.lon_channel || '',
        alt_channel: values.alt_channel || '',
        azm_channel: values.azm_channel || '',
        spd_channel: values.spd_channel || '',
      })

      set.objects_list = set.objects_list
        .map((o: WialonRetranslatorObject, i: number) => ({ ...o, id: i + 1 }))

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  const onSaveBindingHandler = (data: Partial<ChannelsTableData>[]) => {
    if (data.length) {
      form.setFieldValue(`${activeChannelField}`, keyToId(String(data[0].key)))
      form.setFieldValue(`${activeChannelField}_name`, data[0].name)
    }
    setActiveChannelField('')
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        onClick={() => setIsModalOpen(true)}
      />

      <AddModal
        title="объекта"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={() => {
          setIsModalOpen(false)
          form.resetFields()
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onAdd)
          }}
        >
          <Form.Item<AddWRFields>
            label="Идентификатор устройства"
            name="wid"
            rules={[{ required: true, message: 'Введите идентификатор' }]}
          >
            <Input />
          </Form.Item>

          {WRObjectChannels.map(item => (
            <FormChannelBinding
              key={item.name + '_field'}
              label={item.label}
              name={item.name}
              onClick={() => {
                setIsBindingOpen(true)
                setIsBindingActive(true)
                setActiveChannelField(item.name)
              }}
              onClear={() => {
                form.setFieldValue(item.name, '')
                form.setFieldValue(`${item.name}_name`, '')
              }}
            />
          ))}
        </Form>

        {isBindingActive && (
          <BindingChannels
            boundChannels={[]}
            isOpen={isBindingOpen}
            isMultiple={false}
            onSave={onSaveBindingHandler}
            onClose={() => {
              setIsBindingOpen(false)
              setTimeout(() => setIsBindingActive(false), 300)
            }}
          />
        )}
      </AddModal>
    </>
  )
}

export default AddWRObject
