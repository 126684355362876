import React, { FC, useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'
import { EditOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { useAppDispatch, useAppSelector } from 'app/store'
import { ContentComponents } from 'app/providers/content-provider'
import { Module, selectActiveModule, selectedPlugin, setActiveModule } from 'entities/module'
import type { ChangeModuleType } from 'entities/module'
import { addTab } from 'entities/tab'
import { API_URL } from 'shared/config'
import { changeModule } from 'shared/api/modules'
import keyToId from 'shared/lib/key-to-id'
import formValidate from 'shared/lib/form-validate'
import EditModal from 'shared/ui/modals/edit-modal'

export const ChangeModule: FC<{ module: Module | undefined }> = ({ module }) => {
  const dispatch = useAppDispatch()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const activeModule = useAppSelector(selectActiveModule)
  const activePlugin = useAppSelector(selectedPlugin)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  useEffect(() => {
    if (module)
      form.setFieldsValue({ editName: module.name, editGroup: module.group,  editDescription: module.description })
  }, [module])

  const onChange = (values: ChangeModuleType) => {
    setLoading(true)

    if (activeModule.key) {
      changeModule(keyToId(activeModule.key), values.editName, values.editGroup, values.editDescription)
        .then(() => {
          setIsModalOpen(false)

          dispatch(setActiveModule({
            key: activeModule.key,
            name: values.editName
          }))

          dispatch(addTab({
            key: activeModule.key,
            label: values.editName,
            component: activePlugin as ContentComponents,
            isActive: true
          }))

          mutate(`${API_URL}/modules`)
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false))
    }
  }

  return (
    <>
      <Button
        type="primary"
        icon={<EditOutlined />}
        size={'small'}
        title={'Редактировать модуль'}
        onClick={() => activeModule.key && setIsModalOpen(true)}
      />

      <EditModal
        title="модуля"
        open={isModalOpen}
        confirmLoading={loading}
        onSave={() => formValidate(form, onChange)}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onChange)
          }}
        >
          <Form.Item
            label="Имя модуля"
            name="editName"
            rules={[{ required: true, message: 'Введите имя модуля' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Группы"
            name="editGroup"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Описание"
            name="editDescription"
          >
            <Input />
          </Form.Item>
        </Form>
      </EditModal>
    </>
  )
}
