import type { ChannelsTableData } from 'entities/channel'
import type { ModuleSettings } from 'entities/module'
import keyToId from '../key-to-id'

const getPosObjectsToSave = (settings: ModuleSettings<any>, selectedId: number, bindingData: Partial<ChannelsTableData>[], selectedChannelType?: string) => (
  settings.config.objects_list.map((item: any) => (
    (item.ind === selectedId)
      ? {
        ...item,
        object: {
          ...item.object,
          [`${selectedChannelType}_channel`]: bindingData.length ? keyToId(String(bindingData[0].key)) : ''
        }
      } : item
  ))
)

export default getPosObjectsToSave
