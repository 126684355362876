import type { Channel } from 'entities/channel'
import type { VarItem, VarNames } from 'shared/types'

/*
* Генерирует данные таблицы, в зависимости
* от количества каналов и переменных
* */
export const createVarsData = (vars: VarItem[], channelsData: Channel[], varNameField: VarNames) => {
  const res: { [key: string]: string }[] = []

  vars.forEach((v: VarItem) => {
    if (v.channels.length) {
      v.channels.forEach((item: string, i: number) => {
        const rowData: { [key: string]: string } = {}
        const channel = channelsData.find(c => c.uid === item)

        if (channel)
          rowData[v[varNameField]!] = channel.name || 'Канал не найден'

        if (res[i])
          res[i] = { ...res[i], ...rowData }
        else
          res.push({
            '#var#': 'Набор ' + (res.length + 1),
            key: varNameField + '-' + (res.length + 1),
            ...rowData
          })
      })
    }
  })

  return res
}
