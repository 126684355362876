import React, { FC, useContext, useState } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input, Space } from 'antd'
import { LinkOutlined, PlusOutlined } from '@ant-design/icons'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { BindingChannels } from 'widgets/binding-channels'
import type { ICMPHost, ICMPSettingsType } from 'widgets/plugins/icmp'
import type { ChannelItem, ChannelsTableData } from 'entities/channel'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import AddModal from 'shared/ui/modals/add-modal'
import omitObject from 'shared/lib/omit-object'
import keyToId from 'shared/lib/key-to-id'
import formValidate from 'shared/lib/form-validate'

type FormFields = {
  hostAddress: string
  resultChannel: string
  resultChannelName: string
  errorChannel: string
  errorChannelName: string
}

export const AddICMPHost: FC = () => {
  const { state: { settings } } = useContext<ModuleContextType<ICMPSettingsType>>(ModuleContext)
  const [resultChannelData, setResultChannelData] = useState<ChannelItem[]>([])
  const [errorChannelData, setErrorChannelData] = useState<ChannelItem[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isBindingOpen, setIsBindingOpen] = useState<boolean>(false)
  const [isBindingResultActive, setIsBindingResultActive] = useState<boolean>(false)
  const [isBindingErrorActive, setIsBindingErrorActive] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()
  const [form] = Form.useForm()

  const onAdd = (values: FormFields) => {
    if (settings) {
      const set = omitObject(settings, ['uid'])

      set.remote_hosts.push({
        id: 0,
        host: values.hostAddress,
        result_channel: values.resultChannel || '',
        error_channel: values.errorChannel || ''
      })

      set.remote_hosts = set.remote_hosts.map((rh: ICMPHost, i: number) => ({ ...rh, id: i + 1 }))

      setLoading(true)

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          form.resetFields()
        })
    }
  }

  const onSaveBindingResultHandler = (data: Partial<ChannelsTableData>[]) => {
    if (data.length) {
      form.setFieldValue('resultChannel', keyToId(String(data[0].key)))
      form.setFieldValue('resultChannelName', data[0].name)
      setResultChannelData([{ key: data[0].key || '', name: data[0].name || '' }])
    }
  }

  const onSaveBindingErrorHandler = (data: Partial<ChannelsTableData>[]) => {
    if (data.length) {
      form.setFieldValue('errorChannel', keyToId(String(data[0].key)))
      form.setFieldValue('errorChannelName', data[0].name)
      setErrorChannelData([{ key: data[0].key || '', name: data[0].name || '' }])
    }
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        onClick={() => setIsModalOpen(true)}
      />

      <AddModal
        title="устройства"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onAdd)}
        onCancel={() => {
          setIsModalOpen(false)
          setErrorChannelData([])
          setResultChannelData([])
          form.resetFields()
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
          initialValues={{
            hostAddress: '',
            resultChannel: '',
            resultChannelName: '',
            errorChannel: '',
            errorChannelName: '',
          }}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onAdd)
          }}
        >
          <Form.Item
            label="Адрес устройства"
            name="hostAddress"
            rules={[{ required: true, message: 'Введите адрес устройства' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <Space>
                Канал отображения результата опроса
                <Button
                  type="primary"
                  icon={<LinkOutlined />}
                  size={'small'}
                  onClick={() => {
                    setIsBindingOpen(true)
                    setIsBindingResultActive(true)
                  }}
                />
              </Space>
            }
            name="resultChannelName"
          >
            <Input className={'input-disabled'} />
          </Form.Item>
          <Form.Item name="resultChannel" hidden><Input /></Form.Item>

          <Form.Item
            label={
              <Space>
                Канал вывода сообщения ошибки
                <Button
                  type="primary"
                  icon={<LinkOutlined />}
                  size={'small'}
                  onClick={() => {
                    setIsBindingOpen(true)
                    setIsBindingErrorActive(true)
                  }}
                />
              </Space>
            }
            name="errorChannelName"
          >
            <Input className={'input-disabled'} />
          </Form.Item>
          <Form.Item name="errorChannel" hidden><Input /></Form.Item>
        </Form>

        {isBindingResultActive && (
          <BindingChannels
            boundChannels={resultChannelData}
            isOpen={isBindingOpen}
            isMultiple={false}
            onSave={onSaveBindingResultHandler}
            onClose={() => {
              setIsBindingOpen(false)
              setIsBindingResultActive(false)
            }}
          />
        )}

        {isBindingErrorActive && (
          <BindingChannels
            boundChannels={errorChannelData}
            isOpen={isBindingOpen}
            isMultiple={false}
            onSave={onSaveBindingErrorHandler}
            onClose={() => {
              setIsBindingOpen(false)
              setIsBindingErrorActive(false)
            }}
          />
        )}
      </AddModal>
    </>
  )
}
