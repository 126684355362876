import React, { useEffect, useLayoutEffect, useState } from 'react'
import type { FC } from 'react'
import { BindingChannels } from 'widgets/binding-channels'
import { AddVar } from 'features/module/variables/add-var'
import { createVarsData } from 'entities/module'
import type { ChannelsTableData, ChannelItem } from 'entities/channel'
import type { VarItem } from 'shared/types'
import keyToId from 'shared/lib/key-to-id'
import { useChannels } from 'shared/hooks/use-channels'
import HeaderCell from 'shared/ui/table/header-cell'
import { TableResponsive } from 'shared/ui/table/table-responsive'
import type { CustomColumn } from 'shared/ui/table/table-responsive'
import { generateColumns } from '../model/generate-columns'
import type { VariablesTableProps } from '../types'

export const VariablesTable: FC<VariablesTableProps> = ({ variables, varNameField, parentRef, onSave }) => {
  const [columns, setColumns] = useState<CustomColumn[]>([])
  const [tableData, setTableData] = useState<{ [key: string]: string }[]>([])
  const [boundChannels, setBoundChannels] = useState<ChannelItem[]>([])
  const [channelsArr, setChannelsArr] = useState<string[] | null>(null)
  const [currVar, setCurrVar] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isBindingLoading, setIsBindingLoading] = useState<boolean>(false)
  const { data: channelsData, isLoading: isDataLoading } = useChannels(channelsArr)

  useLayoutEffect(() => {
    if (variables) {
      const { channelsUid, cols } = generateColumns(variables, varNameField)

      setColumns(cols)
      setChannelsArr(channelsUid)
    }
  }, [variables])

  useEffect(() => {
    if (channelsData && variables)
      setTableData(createVarsData(variables, channelsData.channels, varNameField))
  }, [channelsData, variables])

  const onSaveChannelsHandler = (data: Partial<ChannelsTableData>[]) => {
    setIsBindingLoading(true)
    const newChannels = data.map(d => keyToId(String(d.key)))
    let newVars: VarItem[] = []

    if (variables) {
      newVars = variables.map((v: VarItem) => (
        (v[varNameField] === currVar) ?
          { ...v, channels: newChannels } : v
      ))
    }

    return onSave(newVars).then(() => setIsBindingLoading(false))
  }

  return (
    <>
      <TableResponsive
        columns={(columns.length > 1) ? columns : []}
        dataSource={(columns.length > 1) ? tableData : []}
        parentRef={parentRef}
        loading={isDataLoading}
        titleComponents={[
          <AddVar
            key={'AddVar'}
            variables={variables}
            varNameField={varNameField}
            onEdit={onSave}
          />
        ]}
        components={{
          header: {
            cell: ({ dataIndex, title, ...restProps }: any) => (
              variables.length ? (
                <HeaderCell
                  title={title}
                  tableData={tableData}
                  dataIndex={dataIndex}
                  variables={variables}
                  varNameField={varNameField}
                  onMenuClick={(data: ChannelItem[]) => {
                    setBoundChannels(data)
                    setCurrVar(dataIndex || null)
                    setIsModalOpen(true)
                  }}
                  onEdit={onSave}
                  {...restProps}
                />
              ) : null
            )
          },
          body: {
            cell: (props: any) => (
              <td
                {...props}
                style={{ backgroundColor: (props.children[1] === 'Канал не найден') ? '#ff7875' : 'transparent' }}
              />
            )
          }
        }}
      />

      {currVar && (
        <BindingChannels
          boundChannels={boundChannels || []}
          isLoading={isBindingLoading}
          isOpen={isModalOpen}
          onSave={onSaveChannelsHandler}
          onClose={() => {
            setIsModalOpen(false)
            setBoundChannels([])
            setCurrVar(null)
          }}
        />
      )}
    </>
  )
}
