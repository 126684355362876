import React, { FC, useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'
import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import type { DirTreeData } from 'entities/directory'
import { deleteDirectories, DIR_URL } from 'shared/api/directories'
import keyToId from 'shared/lib/key-to-id'
import DeleteModal from 'shared/ui/modals/delete-modal'

type DeleteDirectoryType = {
  selectedDirectory: DirTreeData | null
  onDelete: () => void
}

export const DeleteDirectory: FC<DeleteDirectoryType> = ({ selectedDirectory, onDelete }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [dirId, setDirId] = useState<string>('')
  const [dirName, setDirName] = useState<string>('')
  const { mutate } = useSWRConfig()

  useEffect(() => {
    if (selectedDirectory) {
      setDirId(() => selectedDirectory.key && keyToId(selectedDirectory.key))
      setDirName(selectedDirectory.title)
    }
  }, [selectedDirectory])

  const onDeleteHandler = () => {
    if (dirId) {
      setLoading(true)

      deleteDirectories([dirId])
        .then(() => {
          setIsModalOpen(false)
          mutate(DIR_URL)
          onDelete()
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
    }
  }

  return (
    <>
      <Button
        type="primary"
        icon={<DeleteOutlined />}
        size={'small'}
        danger
        onClick={() => {
          if ((selectedDirectory?.key !== '') && (selectedDirectory?.key !== 'dir-0'))
            setIsModalOpen(true)
        }}
      />

      <DeleteModal
        title="Удаление директории"
        text={'Удалить директорию'}
        textValue={dirName}
        open={isModalOpen}
        confirmLoading={loading}
        onDelete={() => onDeleteHandler()}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  )
}
