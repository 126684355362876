import React, { useContext, useEffect } from 'react'
import type { FC } from 'react'
import { Col, Form, Input, Row } from 'antd'
import { ModuleContext, setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { dispatchEvent } from 'shared/lib/custom-events'
import filterNums from 'shared/lib/filter-nums'
import type { VisorlabsFields, VisorlabsSettings } from '../types'

export const VisorlabsForm: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<VisorlabsSettings>>(ModuleContext)
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        ip: settings.ip,
        port: String(settings.port),
        max_objects: String(settings.max_objects),
      })
    }
  }, [settings])

  useEffect(() => {
    if (isChanged) {
      form.validateFields()
        .then(
          () => reducerDispatch(setIsChanged(true)),
          () => reducerDispatch(setIsChanged(false)),
        )
    }
  }, [values])

  return (
    <Form
      layout={'vertical'}
      form={form}
      className={'module'}
      onKeyUp={e => {
        if (e.code === 'Enter' && isChanged) dispatchEvent('module:onPressEnter')
      }}
    >
      <Row gutter={[10, 0]} align={'bottom'}>
        <Col span={6}>
          <Form.Item<VisorlabsFields>
            label={'Адрес интерфейса'}
            name={'ip'}
            rules={[{ required: true, message: 'Введите адрес' }]}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  ip: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<VisorlabsFields>
            label={'Порт'}
            name={'port'}
            rules={[
              { min: 2, message: 'Введите порт' },
              { required: true, message: 'Введите порт' }
            ]}
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  port: +e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<VisorlabsFields>
            label={'Максимальное количество обрабатываемых объектов'}
            name={'max_objects'}
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  max_objects: +e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
