import React, { useState } from 'react'
import type { FC, Dispatch, RefObject, Key, JSX } from 'react'
import { useSWRConfig } from 'swr'
import type { TablePaginationConfig } from 'antd'
import type { TableRowSelection } from 'antd/es/table/interface'
import { setIsChanged, setSettings } from 'layouts/module-layout'
import type { ModuleReducerAction } from 'layouts/module-layout'
import { BindingChannels } from 'widgets/binding-channels'
import { DeleteObject } from 'features/objects/delete-object'
import type { Channel, ChannelItem, ChannelsTableData } from 'entities/channel'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import keyToId from 'shared/lib/key-to-id'
import omitObject from 'shared/lib/omit-object'
import setObjectsConfig from 'shared/lib/objects/set-objects-config'
import { CellWithButton } from 'shared/ui/cell-with-button'
import type { ObjectsTableData, ObjetsToSaveFn } from 'shared/ui/module-objects'
import { EditableRow } from 'shared/ui/table/editable-row'
import { EditableCell } from 'shared/ui/table/editable-cell'
import type { CustomColumn } from 'shared/ui/table/table-responsive'
import { TableResponsive } from 'shared/ui/table/table-responsive'

type ObjectsTableType = {
  settings: any
  reducerDispatch: Dispatch<ModuleReducerAction>
  columns: CustomColumn[],
  channels: Channel[]
  tableData: ObjectsTableData[]
  isLoading: boolean
  parentRef: RefObject<HTMLDivElement>
  pagination: false | TablePaginationConfig
  rowSelection: TableRowSelection<any>
  selectedObjects: Key[]
  AddComponent: JSX.Element
  objectToSaveFn: ObjetsToSaveFn
  onDelete: () => void
}

export const ObjectsTable: FC<ObjectsTableType> = ({
  settings,
  reducerDispatch,
  columns,
  tableData,
  channels,
  isLoading,
  parentRef,
  pagination,
  rowSelection,
  selectedObjects,
  AddComponent,
  objectToSaveFn,
  onDelete,
}) => {
  const [bindChannel, setBindChannel] = useState<ChannelItem[]>([])
  const [selectedId, setSelectedId] = useState<number | null>(null)
  const [selectedChannelType, setSelectedChannelType] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()

  const handleChange = (record: ObjectsTableData, changedValue: Partial<ObjectsTableData>) => {
    reducerDispatch(setSettings(setObjectsConfig(settings, record, changedValue)))
    reducerDispatch(setIsChanged(true))
  }

  const onSaveBindingHandler = async (data: Partial<ChannelsTableData>[]) => {
    if (settings && selectedChannelType && selectedId) {
      setLoading(true)
      const set = omitObject(settings, ['uid'])

      set.config.objects_list = objectToSaveFn(settings, selectedId, data, selectedChannelType)

      return setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
        })
    }
  }

  const onBindClickHandler = (record: any, dataIndex: any) => {
    setBindChannel(() => {
      if (record[dataIndex].id) {
        const currCh = channels.find(ch => ch.uid === record[dataIndex].id)

        if (currCh && currCh.name)
          return [{
            key: currCh ? 'channel-' + currCh.uid : '',
            name: record[dataIndex].name
          }]
      }

      return []
    })
    setSelectedId(+keyToId(record.key))
    setSelectedChannelType(dataIndex)
    setIsModalOpen(true)
  }

  return (
    <>
      <TableResponsive
        columns={columns}
        dataSource={tableData}
        parentRef={parentRef}
        loading={isLoading}
        pagination={pagination}
        titleComponents={[
          AddComponent,
          <DeleteObject
            key={'DeleteObject'}
            settings={settings}
            keys={selectedObjects}
            onDelete={onDelete}
          />
        ]}
        components={{
          body: {
            row: (props: any) => <EditableRow handleChange={handleChange} {...props} />,
            cell: (props: any) => {
              const { isChannel, dataIndex, record, ...restProps } = props

              if (isChannel) {
                return (
                  <CellWithButton
                    {...restProps}
                    style={(props.children[1] === 'Канал не найден') ? { backgroundColor: '#ff7875' } : {}}
                    onClick={() => onBindClickHandler(record, dataIndex)}
                  />
                )
              }
              else
                return <EditableCell record={record} dataIndex={dataIndex} {...restProps} />
            },
          },
        }}
        rowSelection={rowSelection}
      />

      {selectedId && (
        <BindingChannels
          boundChannels={bindChannel}
          isLoading={loading}
          isOpen={isModalOpen}
          isMultiple={false}
          onSave={onSaveBindingHandler}
          onClose={() => {
            setIsModalOpen(false)
            setBindChannel([])
            setSelectedChannelType(null)
            setTimeout(() => setSelectedId(null), 300)
          }}
        />
      )}
    </>
  )
}
