import React, { useContext, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button, Form, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import { BindingChannels } from 'widgets/binding-channels'
import type { NotificationsHandler, NotificationsSettingsType } from 'widgets/plugins/notifications/types'
import type { ChannelItem, ChannelsTableData } from 'entities/channel'
import { setModuleSettings, SETTINGS_URL } from 'shared/api/modules'
import formValidate from 'shared/lib/form-validate'
import omitObject from 'shared/lib/omit-object'
import keyToId from 'shared/lib/key-to-id'
import AddModal from 'shared/ui/modals/add-modal'
import { FormChannelBinding } from 'shared/ui/form/form-channel-binding'

export const AddHandler: FC = () => {
  const { state: { settings } } = useContext<ModuleContextType<NotificationsSettingsType>>(ModuleContext)
  const [channelData, setChannelData] = useState<ChannelItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isBindingActive, setIsBindingActive] = useState<boolean>(false)
  const [isBindingOpen, setIsBindingOpen] = useState<boolean>(false)
  const [form] = Form.useForm()
  const { mutate } = useSWRConfig()

  const onSave = (values: NotificationsHandler) => {
    if (settings) {
      const set = omitObject(settings, ['uid'])

      set.handlers.push({
        id: 0,
        email_subject: values.email_subject || '',
        channel_parameters: values.channel_parameters || '',
        subscribers: [],
        messages: [],
      })

      set.handlers = set.handlers.map((handler: NotificationsHandler, i: number) => ({ ...handler, id: i + 1 }))

      setLoading(true)

      setModuleSettings(settings.uid, set)
        .then(() => mutate(`${SETTINGS_URL}${settings.uid}`))
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false)
          setIsModalOpen(false)
          setChannelData([])
          form.resetFields()
        })
    }
  }

  const onSaveBindingHandler = (data: Partial<ChannelsTableData>[]) => {
    if (data.length) {
      form.setFieldValue('channel_parameters', keyToId(String(data[0].key)))
      form.setFieldValue('channel_parameters_name', data[0].name)
      setChannelData([{ key: data[0].key || '', name: data[0].name || '' }])
    }
  }

  return (
    <>
      <Button
        type="primary"
        size={'small'}
        icon={<PlusOutlined />}
        onClick={() => setIsModalOpen(true)}
      />

      <AddModal
        title="обработчика"
        open={isModalOpen}
        confirmLoading={loading}
        onAdd={() => formValidate(form, onSave)}
        onCancel={() => {
          setIsModalOpen(false)
          setChannelData([])
          form.resetFields()
        }}
      >
        <Form
          form={form}
          layout={'vertical'}
          onKeyUp={e => {
            if (e.code === 'Enter') formValidate(form, onSave)
          }}
        >
          <Form.Item
            label="Тема письма"
            name="email_subject"
          >
            <Input />
          </Form.Item>

          <FormChannelBinding
            key={'channel_parameters_field'}
            label={'Канал параметров'}
            name={'channel_parameters'}
            onClick={() => {
              setIsBindingOpen(true)
              setIsBindingActive(true)
            }}
            onClear={() => {
              form.setFieldValue('channel_parameters', '')
              form.setFieldValue('channel_parameters_name', '')
            }}
          />
        </Form>

        {isBindingActive && (
          <BindingChannels
            boundChannels={channelData}
            isOpen={isBindingOpen}
            isMultiple={false}
            onSave={onSaveBindingHandler}
            onClose={() => {
              setIsBindingOpen(false)
              setIsBindingActive(false)
            }}
          />
        )}
      </AddModal>
    </>
  )
}
